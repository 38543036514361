import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Headline from "../../UI/Headline/Headline";
import TextWithHighlight from "../../UI/TextWithHighlight/TextWithHighlight";
import Container from "../../UI/Container/Container";
import AreasSlider from "../../Lawyers/AreasSlider/AreasSlider";

const Fachgebiete = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <div className="mt-28">
        <Headline
          prefix={t.home.fachgebiete.headlinePrefix}
          text={t.home.fachgebiete.headline}
          subline={t.home.fachgebiete.subline}
        />
        <div className="my-5">
          <AreasSlider />
        </div>
        <TextWithHighlight
          prefix={t.home.fachgebiete.textPrefix}
          text={t.home.fachgebiete.text}
        />
      </div>
    </Container>
  );
};

export default Fachgebiete;
