import { v4 as uuidv4 } from "uuid";
import currencyFormatter from "currency-formatter";
import prettyBytes from "pretty-bytes";
import FileDownload from "js-file-download";
import Location from "./types/Location";
import moment from "moment-timezone";

export const backendDomain =
  process.env.NODE_ENV === "production"
    ? "https://wilms-homepage-api.think-internet.de"
    : "http://localhost:8100";

export const s3PublicStoragePrefix =
  "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms-homepage.storage/";

export const FILE_SYSTEM_ROOT = "root";

export const imageMaxFileSizeInBytes = 1000000;
export const imageMimeTypes = ["image/*"];

export const fileMimeTypes = ["application/pdf", "image/*"];
export const fileMaxFileSizeInBytes = 25000000;

export const maxInputLength = 255;

export const getUUID = () => uuidv4();

export const getPercentage = (value: number, percentage: number) => {
  return ((value || 0) / 100) * percentage;
};

export const round = (value: number) => {
  return Number.parseFloat(value.toFixed(2));
};

export const roundUp = (value: number) => {
  return Math.ceil(value);
};

export const getDateInputFormat = (date: Date) => {
  const fill = (number: number) => {
    const numberAsString = `${number}`;
    return numberAsString.length === 1 ? `0${number}` : number;
  };

  const day = fill(date.getDate());
  const month = fill(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getDateTimeInputFormat = (date: Date) => {
  const fill = (number: number) => {
    const numberAsString = `${number}`;
    return numberAsString.length === 1 ? `0${number}` : number;
  };

  const day = fill(date.getDate());
  const month = fill(date.getMonth() + 1);
  const year = date.getFullYear();
  const minute = fill(date.getMinutes());
  const hour = fill(date.getHours());
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const frontendDomain =
  process.env.NODE_ENV === "production"
    ? "https://wilms.think-internet.de"
    : "http://localhost:3000";

export const formatFileSize = (sizeInBytes, locale = "de") => {
  return prettyBytes(sizeInBytes, { locale });
};

export const triggerClientSideDownload = (data, filename) =>
  FileDownload(data, filename);

export const downloadFile = async (name: string, blobData: Blob) => {
  const text = await blobData.text();
  const newBlob = new Blob([text], {
    type: "text/plain;charset=utf-8",
  });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(newBlob);
  link.download = name;
  link.click();
};

export const downloadFileFromURL = (name: string, url: string) => {
  var link = document.createElement("a");
  link.href = url;
  link.download = name;
  link.dispatchEvent(new MouseEvent("click"));
};

export const formatEUR = (amount) => {
  return currencyFormatter.format(amount, {
    locale: "de-DE",
    symbol: "€",
    decimal: ",",
    thousand: ".",
    format: "%v %s",
  });
};

export const formatNumber = (value) => {
  return new Intl.NumberFormat("de-DE").format(value);
};

export const validateMail = (mail) => {
  const mailRegex = new RegExp("^\\S+@\\S+\\.\\S+$");
  return mailRegex.test(mail);
};

const fill = (number: Number) => {
  const numberAsString = `${number}`;
  return numberAsString.length === 1 ? `0${numberAsString}` : numberAsString;
};

export const prettyLocation = (location: Location) => {
  if (!location) return "";
  return location.name;
};

export const getPrettyTime = (inputDate: Date) => {
  const date = moment.tz(inputDate, moment.tz.guess());
  const minute = fill(date.minutes());
  const hour = fill(date.hours());
  return `${hour}:${minute}`;
};
export const getPrettyDateTime = (inputDate: Date) => {
  const date = moment.tz(inputDate, moment.tz.guess());
  const day = fill(date.date());
  const month = fill(date.month() + 1);
  const year = date.year();
  const minute = fill(date.minutes());
  const hour = fill(date.hours());
  return `${day}.${month}.${year} ${hour}:${minute}`;
};
export const getPrettyDate = (inputDate: Date) => {
  const date = moment.tz(inputDate, moment.tz.guess());
  const day = fill(date.date());
  const month = fill(date.month() + 1);
  const year = date.year();
  return `${day}.${month}.${year}`;
};

export const getNameSuffix = (t: any, firstname: string) => {
  const useSpecialIndexOnNamesWithEnding = ["s", "z", "x"];
  if (!firstname)
    return `${t.index.uploadForm.story.placeholderName}${t.generic.firstnameSuffix}`;
  if (
    !!useSpecialIndexOnNamesWithEnding.includes(
      firstname.trim()[firstname.trim().length - 1]
    )
  )
    return `${firstname.trim()}${t.generic.firstnameSpecialSuffix}`;
  return `${firstname.trim()}${t.generic.firstnameSuffix}`;
};
