import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.ARBEITSRECHT,
  content: [
    "Wir beraten und betreuen Arbeitgeber und Arbeitnehmer in allen Bereichen des individuellen und kollektiven Arbeitsrechts. Dies umfasst neben der Beratung im täglichen Geschäft vor allem die Verhandlungsführung mit Betriebs- und Personalräten, die Vertretung vor Arbeits-, Zivil- und Verwaltungsgerichten und vor Einigungsstellen.",
    "Unser Leistungsspektrum im Arbeitsrecht umfasst u.a.:",
    [
      "Gestaltung von Arbeitsverträgen,",
      "Beratung von Arbeitnehmern und Arbeitgebern vor und nach Ausspruch der Kündigung,",
      "Vertretung in Kündigungsschutzprozessen",
      "Verhandlung und Abschluss von Vergleichen, Abwicklungs- und Aufhebungsverträgen einschließlich Abfindungen und der damit verbundenen sozial- und steuerrechtlichen Problemstellungen,",
      "Beratung und Vertretung bei Streitigkeiten über Teilzeitarbeit, Zeugnisse, Versetzungen, Urlaub und Abmahnungen,",
    ],
  ],
  bullets: [
    "Arbeits- und Dienstverträge",
    "Kündigungen",
    "Kündigungsschutz",
    "Aufhebungsvereinbarungen",
    "Sozialversicherungsrecht",
    "Betriebsverfassungsrecht",
  ],
  more: [],
};

export default data;
