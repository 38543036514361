import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Container from "../../UI/Container/Container";
import Accordion from "../../UI/Accordion/Accordion";
import ApplicationForm from "./Form";

const Application = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  const items = t.career.openPositions.items.map((item, i) => ({
    title: item.title,
    content: (
      <div className="flex flex-col gap-12 mt-5">
        <div className="flex items-start gap-20">
          <div className="text-blue whitespace-nowrap w-40 shrink-0">
            {t.career.openPositions.tasks}
          </div>
          <div>{item.tasks}</div>
        </div>
        <div className="flex items-start gap-20">
          <div className="text-blue whitespace-nowrap w-40 shrink-0">
            {t.career.openPositions.expectations}
          </div>
          <div>{item.expectations}</div>
        </div>
        <div className="flex items-start gap-20">
          <div className="text-blue whitespace-nowrap w-40 shrink-0">
            {t.career.openPositions.offerings}
          </div>
          <div>{item.offerings}</div>
        </div>
        <div className="w-8/12 mx-auto">
          <ApplicationForm title={item.title} />
        </div>
      </div>
    ),
    key: i,
  }));

  return (
    <Container>
      <div className="mt-24 text-center text-2xl text-blue">
        {t.career.openPositions.headline}
      </div>
      <div className="mt-10">
        <Accordion items={items} />
      </div>
    </Container>
  );
};

export default Application;
