import Layout from "../UI/Layout/Layout";
import Hero from "../UI/Hero/Hero";
import hero from "../../assets/image/home/hero/hero.webp";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Intro from "./Intro/Intro";
import Fachgebiete from "./Fachgebiete/Fachgebiete";
import Checklist from "./Checklist/Checklist";
import Contact from "../Contact/ContactSection/ContactSection";
import Team from "../Lawyers/Team/Team";

const Home = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          subtitle={t.home.hero.subtitle}
          title={t.home.hero.title}
          subline={t.home.hero.subline}
          cta={t.home.hero.cta}
          ctaLink={"#"}
          fullWidthUnderline
        />
        <Intro />
        <Fachgebiete />
        <Team limit={8} />
        <Checklist />
        <Contact />
      </div>
    </Layout>
  );
};

export default Home;
