import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.HANDELUNDGESELLSCHAFTSRECHT,
  content: [
    "Gesellschaftsrecht",
    "Wir beraten Sie bei der Gründung einer Gesellschaft, unterstützen Sie bei deren Leitung und der laufenden Beteiligung daran und vertreten Sie bei der Auflösung oder dem Ausscheiden aus der Gesellschaft.",
    "Wir stehen Ihnen beratend zur Seite, u.a.",
    [
      "bei der Rechtsformwahl, der Rechtsformänderung, der Umstrukturierung",
      "bei der Gestaltung des Gesellschaftsvertrages",
      "bei der Existenzgründung",
      "bei Satzungsänderungen, bei Anfechtung von Gesellschafterbeschlüssen",
      "bei dem Abschluss, Änderung und Beendigung von Geschäftsführer- anstellungsverträgen",
      "bei der Geschäftsführung und Vertretung in Personengesellschaft (oHG, KG, GbR)",
      "bei der Auflösung und Auseinandersetzung einer Gesellschaft",
      "bei der Vererbung und Unternehmensnachfolge",
      "bei Insolvenz",
    ],
    "Handelsrecht",
    "Wir stehen Ihnen beratend zur Seite, insbesondere bei",
    [
      "der Gestaltung zum Recht des Handelsstandes (Kaufmann, Firmenrecht, HGB)",
      "der Wahl der Vertriebsformen",
      "der Gestaltung von Vertriebsverträgen",
      "Handelsgeschäften, Handelsbräuchen, Handelskauf",
      "der Gestaltung von Allgemeinen Geschäftsbedingungen (AGB) unter Kaufleuten",
    ],
  ],
  bullets: [
    "Vertragsgestaltung",
    "Insolvenzrecht",
    "Gesellschaftsgründungen und Umstrukturierungen",
  ],
  more: [],
};

export default data;
