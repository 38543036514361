import React from "react";
import SlickSlider from "react-slick";
import "./Slider.scss";
import previousIcon from "../../../assets/image/slider/previous.png";
import nextIcon from "../../../assets/image/slider/next.png";
import useIsBreakpoint, { Breakpoint } from "../../hooks/useIsBreakpoint";

interface CustomArrowProps {
  onClick?: () => void;
  className?: string;
  image: string;
}

const CustomArrow: React.FC<CustomArrowProps> = ({
  onClick,
  className,
  image,
}) => <img className={className} src={image} alt="" onClick={onClick} />;

type Props = {
  items: React.ReactNode[];
};

const Slider: React.FC<Props> = ({ items }) => {
  const isBreakpoint = useIsBreakpoint(Breakpoint.MD);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isBreakpoint ? 2 : 4,
    slidesToScroll: 1,
    arrows: items.length > 4,
    nextArrow: <CustomArrow image={nextIcon} />,
    prevArrow: <CustomArrow image={previousIcon} />,
  };

  return (
    <SlickSlider {...settings} className="slider-container">
      {items.map((item, index) => (
        <div key={index} className="slide">
          {item}
        </div>
      ))}
    </SlickSlider>
  );
};

export default Slider;
