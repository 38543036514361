import { Person } from "./types";
import diedrichWilms from "./diedrichWilms";
import friedrichSchulze from "./friedrichSchulze";
import thorstenMuellerWinterhager from "./thorstenMuellerWinterhager";
import felixFreist from "./felixFreist";
import kathrinSchwartz from "./kathrinSchwartz";
import robertSchad from "./robertSchad";
import bettinaOtto from "./bettinaOtto";
import thomasAbel from "./thomasAbel";

const people: Person[] = [
  diedrichWilms,
  friedrichSchulze,
  thorstenMuellerWinterhager,
  felixFreist,
  kathrinSchwartz,
  robertSchad,
  bettinaOtto,
  thomasAbel,
];

export default people;
