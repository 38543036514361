import { useSelector } from "react-redux";
import props from "../../../redux/props";
import bg from "../../../assets/image/career/quote/background.png";

const Quote = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <div
      className="mt-24 h-[30vh] bg-center bg-cover flex items-center justify-center"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="text-white text-2xl font-bold text-center w-10/12 md:w-1/2">
        {t.career.quote.text}
      </div>
    </div>
  );
};

export default Quote;
