import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "eugh-verbaende-gegen-dsgvo-verletzungen",
  title: "EuGH: Verbände können gegen DSGVO-Verletzungen vorgehen",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "thorsten-mueller-winterhager",
  date: new Date("2022-05-10"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Der Europäische Gerichtshof (EuGH) hat entschieden, dass Verbraucherschutzverbände gegen Datenschutzverstöße auch unabhängig von einer konkreten Verletzung des Rechts einer betroffenen Person und ohne deren Auftrag per Abmahnung und Klage vorgehen können (Urt. v. 28.4.2022 – C-319/20 – Meta Platforms Ireland ltd.).",
      ],
    },
    {
      type: ContentSectionType.HEADLINE,
      content: ["Verbraucherzentrale gegen Meta"],
    },
    {
      type: ContentSectionType.TEXT,
      content: [
        "Der Verbraucherzentrale Bundesverband (vzbv) war gegen die Praktiken von Meta (ehemals Facebook) im App-Zentrum des sozialen Netzwerks Facebook vorgegangen. Er hatte Meta wegen diverser Datenschutzverstöße abgemahnt und schließlich auf Unterlassung verklagt.",
      ],
    },
    {
      type: ContentSectionType.HEADLINE,
      content: ["Bislang: Abmahnung von Datenschutzverstößen umstritten"],
    },
    {
      type: ContentSectionType.TEXT,
      content: [
        "Bislang war umstritten, ob Verbraucherschutzverbände z.B. auf Grundlage wettbewerbsrechtlicher Vorschriften berechtigt sind, gegen Datenschutzverstöße vorzugehen. Die deutsche Rechtsprechung ging überwiegend davon aus, dass die Vorschriften der DS-GVO vorrangig seien und ein anlassloses Vorgehen von Verbänden ohne Auftrag einer betroffenen Person ausschließen. Abmahnungen und Klagen von Datenschutzverstößen unter Verweis auf wettbewerbsrechtliche Vorschriften waren den Verbänden daher bislang verwehrt. Die Verfolgung von Datenschutzverstößen blieb vielmehr vorrangig den Datenschutzbehörden vorbehalten. Diese werden in der Regel erst bei konkretem Anlass tätig. Der vzbv hatte deshalb das Verfahren gegen Meta anhängig gemacht, um die offene Rechtsfrage durch den EuGH klären zu lassen.",
        "Der EuGH gab den Verbraucherschützern nun recht. Er entschied, dass die DS-GVO einen Verband nicht daran hindert, zur Wahrung von Verbraucherinteressen gegen Datenschutzverstöße vorzugehen. Art. 80 Abs. 2 DS-GVO räume den Mitgliedstaaten einen Ermessensspielraum ein, die Verfolgung von Rechtsverstößen auch unabhängig vom Auftrag einer verletzten, betroffenen Person vorzusehen. Danach können Einrichtungen ohne Gewinnerzielungsabsicht zur Verfolgung von Zielen im öffentlichen Interesse Datenschutzverstöße verfolgen, wenn sie eine Rechtsverletzung iSd DS-GVO erkennen. Der EuGH sieht in der Möglichkeit solcher Verbandsklagen einen sinnvollen Beitrag, die Rechte der betroffenen Personen insgesamt zu stärken, ihnen ein hohes Schutzniveau zu gewährleisten und damit die Ziele der DS-GVO zu fördern.",
      ],
    },
    {
      type: ContentSectionType.HEADLINE,
      content: [
        "Konsequenzen der Entscheidung: Zunahme von Abmahnungen wegen DS-GVO Verstößen zu erwarten",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: [
        "Für die Praxis bedeutet dies, dass Verbraucherschutzverbände nun ohne konkreten Anlass festgestellte Datenschutzverstöße abmahnen und Unterlassungsansprüche klageweise geltend machen können. Gerade der vzbv hatte bereits in den letzten beiden Jahren zahlreiche Abmahnung wegen z.B. fehlender oder fehlerhafter Cookie-Banner sowie anderer Datenschutzverletzungen ausgesprochen. Zu erwarten steht, dass derartige Abmahnungen wegen vermeintlicher Datenschutzverstöße nun weiter zunehmen werden.",
        "Cookie-Banner, Datenschutzerklärung, Newsletter und Kontaktformular. Lassen Sie Ihren Internetauftritt auf DS-GVO-Konformität überprüfen, bevor die Abmahnung kommt.",
      ],
    },
  ],
};

export default data;
