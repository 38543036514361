import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "robert-schad",
  firstname: "Robert",
  lastname: "Schad",
  title: "Rechtsanwalt",
  description: "",
  areas: [Area.ARBEITSRECHT],
  subAreas: [Area.ARBEITSRECHT],
  phone: "030 / 75 79 64 0",
  mail: "robert.schad@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1972 in Berlin",
    "Studium in Berlin",
    "Referendariat beim Kammergericht Berlin",
    "Rechtsanwalt seit 2001 in Berlin",
    "2001 bis 2008 Justiziar einer internationalen Logistikgruppe in Berlin",
    "2008 bis 2011 Leiter Personal in mittelständischen Logistikunternehmungen in Ludwigsfelde/Zossen",
    "Seit 2011 Leiter einer Personalabteilung eines internationalen Dienstleistungskonzerns",
  ],
  memberships: [],
};

export default data;
