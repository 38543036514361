import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "bgh-ebay-auktionen-eigengebote-des-anbieters-unwirksam",
  title: "BGH: Ebay-Auktionen – Eigengebote des Anbieters unwirksam",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "thorsten-mueller-winterhager",
  date: new Date("2017-07-12"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Ebay-Nutzer kennen das: Sie bieten auf ein Angebot mit einem Euro Startgebühr und werden bis zum Ende der Auktion ständig überboten. Zum Schluss ist das vermeintliche Schnäppchen viel teurer als gedacht, oder es geht an den fleißigen Mitbieter. Nur allzu häufig stellt sich heraus, dass der Mitbieter der Verkäufer selbst war, der den Preis in die Höhe treiben wollte.",
        "Der BGH hat diesem Treiben nun einen Riegel vorgeschoben: In seinem Urteil vom 24. August 2016 (Az.VIII ZR 100/15) hat er klargestellt, dass Gebote des Verkäufers auf eigene Auktionen, sogenannte „Eigengebote“, unwirksam sind. Bieter und Anbieter einer ebay-Auktion müssten zwei verschiedene Personen sein. Wer auf seine eigenen Auktionen biete, verstoße nicht nur gegen ebay-AGB, sondern auch gegen wichtige Grundsätze des Vertragsrechts.",
        "Die Folge: Der Kaufvertrag kommt mit dem regulären Bieter zustande, der das letzte wirksame Gebot abgegeben hat.",
        "Der Fall: Der Beklagte hatte seinen alten VW Golf zum Startpreis von nur 1 Euro auf ebay.de eingestellt. Ein ebay-Nutzer bot zunächst 1,50 Euro und erhöhte jeweils auf Gegengebote des einzigen Mitbieters, bis die Auktion schließlich zu Gunsten dieses Mitbieters mit einem Gebot von 17.000 Euro endete. Wie sich herausstellte war der Mitbieter der Verkäufer selbst, der von einem anderen Account aus Gebote abgegeben hatte, um den Kaufpreis in die Höhe zu treiben. Der glücklose Bieter wollte sich mit dem Ergebnis der Auktion nicht abfinden und klagte.",
        "Der BGH gab ihm Recht und sprach ihm, da das Fahrzeug inzwischen vom Beklagten verkauft worden war, Schadensersatz zu. Der Kläger habe das Fahrzeug zum Preis von 1,50 Euro erworben. Dies sei das letzte wirksame Gebot der Auktion gewesen. Alle späteren Gebote des Klägers seien als bloße Reaktionen auf die unwirksamen Gebote des Verkäufers unbeachtlich. Besonders bitter dürfte für den Verkäufer gewesen sein, dass er selbst den VW Golf nach der manipuliert Auktion für lediglich 13.320 Euro an einen Dritten weiterverkauft hatte, nun aber Schadensersatz in Höhe von 16.500 an den Kläger Euro zahlen musste. Das Mitbieten auf die eigene Auktion kann sich daher zu einem stattlichen Verlustgeschäft auswachsen.",
        "Das Fazit: Wer den Verdacht hat, dass es bei einer Auktion nicht mit rechten Dingen zugegangen ist, sollte dies bei ebay anzeigen und Nachforschungen anstellen. Stellt sich heraus, dass „Eigengebote“ abgegeben wurden, dann kann man auf Erfüllung des geschlossenen Kaufvertrages oder Schadensersatz klagen.",
      ],
    },
  ],
};

export default data;
