import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "mietpreisbremse-in-brandenburg-auch-nach-erneutem-erlass-unwirksam",
  title: "Mietpreisbremse in Brandenburg auch nach erneutem Erlass unwirksam!",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "felix-freist",
  date: new Date("2019-06-06"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Durch Urteil vom 5. Juni 2019 hat das Landgericht Potsdam in zweiter Instanz entschieden, dass die brandenburgische Mietpreisbegrenzungsverordnung auch nach ihrem erneuten Erlass unwirksam ist; dies jedenfalls, soweit es um solche Mietverträge geht, die bis zum 3. April 2019 abgeschlossen worden sind.",
        "Nach dem erstmaligen Erlass der brandenburgischen Mietpreisbegrenzungsverordnung vom 8. Dezember 2015 hatten zwei Potsdamer Mieter auf die Anpassung ihrer Miete geklagt. Das Amtsgericht Potsdam wies die Klage im September 2018 mit dem Argument zurück, dass die Verordnungsbegründung entgegen der gesetzlichen Vorgaben nicht veröffentlicht worden war. Hiergegen legten die Mieter Berufung ein, über die das Landgericht Potsdam am 5. Juni 2019 verhandelte.",
        "In der Zwischenzeit hatte die brandenburgische Landesregierung die Verordnung erneut erlassen (veröffentlicht im Gesetz- und Verordnungsblatt am 4. April 2019) und dieses Mal die Verordnungsbegründung beigefügt. Gleichzeitig wurde angeordnet, dass die neue Verordnung rückwirkend zum 1. Januar 2016 in Kraft treten und die ursprüngliche Verordnung rückwirkend ablösen soll.",
        "Nach zutreffender Ansicht der Landgerichtes Potsdam stellt dies eine echte Rückwirkung dar, die verfassungsrechtlich unzulässig ist. Die Berufung der Mieter wurde deshalb zurückgewiesen und das erstinstanzliche Urteil bestätigt. Die Entscheidung des Landgerichtes ist nicht revisibel und somit rechtskräftig.",
        "Danach ist die brandenburgische Mietpreisbegrenzungsverordnung auf all diejenigen Mietverträge nicht anwendbar, die bis zum 3. April 2019 abgeschlossen worden sind. Nicht entschieden hat das Landgericht Potsdam, ob die neue Mietpreisbegrenzungsverordnung für spätere Mietverhältnisse Gültigkeit entfaltet. Dies wird sich gegebenenfalls in nachfolgenden Klageverfahren klären.",
      ],
    },
  ],
};

export default data;
