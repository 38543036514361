import styled from "styled-components";
import placeholder from "../../../assets/image/kanzlei/gallery/placeholder.jpg";
import Container from "../../UI/Container/Container";

const ItemWrapper = styled.div<{ slider?: boolean }>`
  ${({ slider }) =>
    !!slider ? "padding: 0 1rem;" : "width: calc(100% / 4 - 25px * 3 / 4);"}

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ slider }) =>
      !!slider ? "padding: 0 1rem;" : "width: calc(100% / 2 - 15px / 2);"}
  }
`;

const Gallery: React.FC<{ slider?: boolean }> = ({ slider = false }) => {
  const images = [
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
    placeholder,
  ];

  const getItems = () => {
    return images.map((img, i) => (
      <ItemWrapper key={i} slider={slider}>
        <img
          src={img}
          alt=""
          className="max-w-full max-h-full object-cover aspect-square"
        />
      </ItemWrapper>
    ));
  };

  return (
    <Container>
      <div className="flex flex-wrap gap-[15px] md:gap-[25px] my-16">
        {getItems()}
      </div>
    </Container>
  );
};

export default Gallery;
