import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.ERBRECHT,
  content: [
    "Im Erbschaftsrecht gestalten, beraten und vertreten wir Sie bzgl. des gesamten Nachlasses, dessen Errichtung und Verwaltung. Insbesondere begleiten wir Sie bei der Testamentserrichtung, der vorweggenommene Erbfolge bzw. der Schenkung zu Lebzeiten unter Berücksichtigung rechtlicher und steuerrechtlicher Aspekten.",
    "Unsere Leistungen im Erbrecht umfassen ua.:",
    [
      "Testamentsgestaltung (Erbfolge, Berliner Testament, Pflichtteilsansprüche, Vermächtnisse)",
      "Beratung im Erbfall, Annahme oder Ausschaltung des Erbes",
      "Durchsetzung von Auskunftsansprüchen",
      "Testaments- und Nachlassvollstreckung",
      "Erstellung einer Patientenverfügung",
      "Erstellung einer Vorsorgevollmacht",
    ],
  ],
  bullets: [
    "Testamentsgestaltung (Erbfolge, Berliner Testament, Pflichtteilsansprüche, Vermächtnisse)",
    "Beratung im Erbfall, Annahme oder Ausschaltung des Erbes",
    "Durchsetzung von Auskunftsansprüchen",
    "Testaments- und Nachlassvollstreckung",
    "Erstellung einer Patientenverfügung",
    "Erstellung einer Vorsorgevollmacht",
  ],
  more: [],
};

export default data;
