import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "felix-freist",
  firstname: "Felix",
  lastname: "Freist",
  title: "Rechtsanwalt",
  description: "",
  areas: [Area.MIETUNDWOHNUNGSEIGENTUMSRECHT],
  subAreas: [
    Area.MIETUNDWOHNUNGSEIGENTUMSRECHT,
    Area.ALLGEMEINESZIVILRECHT,
    Area.VERKEHRSRECHT,
  ],
  phone: "030 / 75 79 64 0",
  mail: "felix.freist@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1980 in Berlin",
    "Studium in Berlin",
    "Referendariat beim Oberlandesgericht Düsseldorf und bei der Europäischen Kommission in Brüssel",
    "Rechtsanwalt seit 2011 in Berlin",
  ],
  memberships: [],
};

export default data;
