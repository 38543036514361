import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "mietpreisbremse-jetzt-auch-in-brandenburg-unwirksam",
  title: "Mietpreisbremse: Jetzt auch in Brandenburg unwirksam!",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "felix-freist",
  date: new Date("2018-10-06"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        'Während sich die Justizministerin Barley dafür einsetzt, die sog. "Mietpreisbremse" zugunsten der Mieter weiter zu verschärfen, ist die entsprechende Verordnung nunmehr auch für das Land Brandenburg für unwirksam erklärt worden.',
        "Dies entschied jedenfalls das AG Potsdam mit Urteil vom 27. September 2018. Darin ist die Klage der Mieter mit der Begründung abgewiesen worden, dass die Mietpreisbegrenzungsverordnung des Landes Brandenburg unwirksam sei, da deren Begründung nicht veröffentlicht worden ist.",
        "Vorbehaltlich der noch nicht eingetreten Rechtskraft dieser Entscheidung ist damit nach Bayern, Hamburg und Hessen nun auch im Land Brandenburg die Mietpreisbremse nicht wirksam.",
        "In rechtlichen Fragestellungen hierzu beraten wir Sie gern.",
      ],
    },
  ],
};

export default data;
