import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "die-miete-in-der-virus-krise",
  title: "Die Miete in der Virus-Krise",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "felix-freist",
  date: new Date("2020-03-23"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Viele Mieterinnen und Mieter sorgen sich zunehmend um ihre Mietzahlungsverpflichtung. Entsprechendes gilt spiegelbildlich für die Vermieter.",
        "Im Netz verbreiten sich hierzu verschiedene Nachrichten; unter anderem die Mitteilung, dass der Mieter im Fall der Krise bzw. der „höheren Gewalt“ von der Pflicht zur Mietzahlung befreit sei.",
        "Die in diesem Zusammenhang oftmals zitierte Vorschrift des § 1104 ABGB ist eine gesetzliche Regelung aus dem österreichischen Recht. Diese ist in Deutschland nicht anwendbar. ",
        "Kurz zusammengefasst gilt nach deutschem Recht aktuell folgendes:",
        "Der Mieter bleibt zur Mietzahlung verpflichtet. Dies sowohl in der Wohnraummiete, als auch in der Gewerbemiete.",
        "Unerheblich ist die finanzielle Leistungsfähigkeit des Mieters. Ohne Bedeutung ist bislang auch, ob der gewerbliche Mieter von einer behördlichen Anordnung zur Ladenschließung betroffen ist.",
        "Die Pflicht zur Mietzahlung bleibt bestehen. Im Fall des wiederholten Mietrückstandes droht die fristlose Kündigung.",
        "Die Bundesregierung beabsichtigt aktuell sowohl im Bereich der Wohnraummiete, als auch in der Gewerbemiete eine kurzfristige Gesetzesänderung herbeizuführen, aufgrund derer die Kündigung trotz bestehender Mietrückstände ausgeschlossen sein soll. Die Pflicht zur Mietzahlung bleibt aber auch hiervon unberührt.",
        "Rechtliche Fragen hierzu beantworten wir gern.",
      ],
    },
  ],
};

export default data;
