import Layout from "../UI/Layout/Layout";
import Hero from "../UI/Hero/Hero";
import hero from "../../assets/image/blog/hero.png";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Intro from "./Intro/Intro";
import List from "./List/List";
import useBlog from "../hooks/useBlog/useBlog";
import Container from "../UI/Container/Container";

const Blog = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const [entries, setFilter, setSorting] = useBlog();

  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          small
          subtitle={t.blog.hero.subtitle}
          title={t.blog.hero.title}
          fullWidthUnderline
        />
        <Intro />
        <Container>
          <div className="flex flex-col gap-10 mt-10 pb-20">
            {/* <Filter filter={filter} setFilter={setFilter} /> */}
            <List entries={entries} />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Blog;
