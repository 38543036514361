import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "berliner-mietendeckel-in-der-virus-krise",
  title: "Berliner Mietendeckel in der Virus-Krise",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "kathrin-schwartz",
  date: new Date("2020-04-08"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Der Berliner Senat hat durch Beschluss bestimmt, dass die Sanktionen (Bußgeldverfahren) bei Verstoß des Vermieters gegen seine Informationspflichten nach dem Mietendeckelgesetz (MietenWoG Bln) zunächst für 6 Monate ausgesetzt werden können.",
        "Kommt der Vermieter seinen Informationspflichten gegenüber dem Mieter nicht bis zum 23.04.2020 nach, so kann eine ordnungsbehördliche Verfolgung gegen diese Ordnungswidrigkeit für 6 Monate ausgesetzt werden. ",
        "Dies gilt jedoch nur für den Fall, dass die Unmöglichkeit der Informationserfüllung auf die Auswirkungen der Viruskrise zurückzuführen ist. Ist der Vermieter demnach aufgrund der Corona-Krise nicht in der Lage, seine Informationspflichten fristgerecht (bis zum 23.04.2020) zu erfüllen, so kann er nicht wegen dieser Ordnungswidrigkeit verfolgt werden. Ein ursächlicher Zusammenhang zwischen Krise und Nichterfüllung der Informationspflicht muss vom Vermieter glaubhaft gemacht werden. ",
        "Die Verfolgungsaussetzung gilt nicht pauschal für 6 Monate, sondern nur so lange, wie der Vermieter an der Erfüllung seiner Pflicht gehindert ist.",
        "Für alle anderen Fälle verbleibt es bei der grundsätzlichen Verpflichtung der Vermieter aus dem Mietendeckelgesetz.",
        "Rechtliche Fragen hierzu beantworten wir gern.",
      ],
    },
  ],
};

export default data;
