import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Container from "../../UI/Container/Container";
import styled from "styled-components";

const Item = styled.div`
  width: calc(100% / 4 - 25px * 3 / 4);
`;

const Reasons = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <div className="mt-24 text-center text-2xl text-blue">
        {t.career.reasons.headline}
      </div>
      <div className="flex flex-wrap gap-y-14 gap-x-[25px] mt-10">
        {t.career.reasons.items.map((i) => (
          <Item>
            <div className="text-center text-lg text-blue">{i.title}</div>
            <div className="text-center text-sm text-gray mt-3">{i.text}</div>
          </Item>
        ))}
      </div>
    </Container>
  );
};

export default Reasons;
