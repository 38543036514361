import { useEffect, useState } from "react";
import { Area, AreaContent } from "./areas/types";
import areaContentList from "./areas/list";

const useAreas = (areaList?: Area[]) => {
  const [areas, setAreas] = useState<AreaContent[]>();

  useEffect(() => {
    if (!areas) {
      if (areaList) {
        const filteredAreas = areaContentList.reduce((acc, area, i) => {
          if (areaList.includes(area.id)) {
            acc.push(area);
          }
          return acc;
        }, []);
        setAreas(filteredAreas);
      } else {
        setAreas(areaContentList);
      }
    }
  }, [areas, areaList]);

  return areas;
};

export default useAreas;
