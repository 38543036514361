const Headline: React.FC<{
  prefix: string;
  text: string;
  subline?: string;
  invert?: boolean;
}> = ({ prefix, text, invert = false, subline }) => {
  const textColor = invert ? "text-white" : "text-blue";

  return (
    <div>
      <div className={`text-lg md:text-2xl ${textColor}`}>
        {`${prefix} `}
        <span className={`text-lg md:text-2xl font-bold ${textColor}`}>
          {text}
        </span>
      </div>
      {!!subline && (
        <div className={`${textColor} uppercase tracking-widest`}>
          {subline}
        </div>
      )}
    </div>
  );
};

export default Headline;
