import Layout from "../UI/Layout/Layout";
import Hero from "../UI/Hero/Hero";
import hero from "../../assets/image/home/hero/hero.webp";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Intro from "./Intro/Intro";
import Overview from "./Overview/Overview";
import Container from "../UI/Container/Container";

const SubjectAreas = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          small
          subtitle={t.subjectAreas.hero.subtitle}
          title={t.subjectAreas.hero.title}
        />
        <Intro />
        <Container>
          <Overview />
        </Container>
      </div>
    </Layout>
  );
};

export default SubjectAreas;
