import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Container from "../../UI/Container/Container";
import Headline from "../../UI/Headline/Headline";

const Checklist = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <div className="py-14 md:py-28">
        <Headline
          prefix={t.home.checklist.headlinePrefix}
          text={t.home.checklist.headline}
          subline={t.home.checklist.subline}
        />
        <div className="flex flex-col gap-1 mt-10">
          {t.home.checklist.items.map((item, i) => (
            <div key={i}>
              {"+ "}
              {item}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Checklist;
