const TextWithHighlight: React.FC<{
  prefix?: string;
  text: string;
  invert?: boolean;
}> = ({ prefix, text, invert = false }) => {
  const textColor = invert ? "text-white" : "text-blue";

  return (
    <div className={`${textColor} text-md`}>
      {!!prefix && <span className={`${textColor} font-bold`}>{prefix}</span>}
      {text}
    </div>
  );
};

export default TextWithHighlight;
