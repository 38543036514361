import { useState } from "react";
import Progress from "../Progress/Progress";
import { functional } from "@think-internet/zeus-frontend-package";
import Routes from "../../../redux/routes";
import { s3Upload } from "@think-internet/zeus-frontend-package/fetch/fetch";
import DragAndDrop from "../DragAndDrop/DragAndDrop";

export type PresignedUploadResponse = {
  presignedURL: string;
  key: string;
  url?: string;
};

export type OnCallbackFile = {
  url: string;
  key: string;
  name: string;
  mimeType: string;
};

type Props = {
  label?: string;
  allowedMimeTypes: string[];
  maxFileSizeInBytes: number;
  uploadType: UPLOAD_TYPES;
  onFilesCallback: (files: OnCallbackFile[] | OnCallbackFile) => void;
  children?: React.ReactNode;
  multiple?: boolean;
  className?: string;
};

export enum UPLOAD_TYPES {
  APPLICATION = "APPLICATION",
}

const DirectFileUpload: React.FC<Props> = ({
  label,
  allowedMimeTypes,
  maxFileSizeInBytes,
  onFilesCallback,
  uploadType,
  multiple = false,
  children,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const getImageUploadPresignedURL = functional.use(
    Routes.GET_FILE_UPLOAD_PRESIGNED_URL
  );

  const process = async (files: File[]) => {
    const outputFiles: OnCallbackFile[] = [];
    for (const file of files) {
      const response: PresignedUploadResponse =
        await getImageUploadPresignedURL({
          type: uploadType,
          meta: { type: file.type, name: file.name, size: file.size },
        });
      if (!response) continue;
      const { presignedURL, url, key } = response;

      const uploadResult = await s3Upload(presignedURL, file, setProgress);
      if (!uploadResult) continue;
      outputFiles.push({ url, key, name: file.name, mimeType: file.type });
    }
    if (outputFiles.length > 0) {
      if (multiple) {
        onFilesCallback(outputFiles);
      } else {
        onFilesCallback(outputFiles[0]);
      }
    }
  };

  return (
    <div className="bg-lightGray h-full w-full">
      {(progress === 0 || progress === 100) && (
        <DragAndDrop
          onFiles={process}
          allowedMimeTypes={allowedMimeTypes}
          maxFileSizeInBytes={maxFileSizeInBytes}
          className="flex items-center justify-center"
        >
          {!!label && !children && (
            <div className="flex items-center justify-center py-10 bg-lightGray">
              <div className="text-sm text-gray">{label}</div>
            </div>
          )}

          {children && children}
        </DragAndDrop>
      )}
      <Progress progress={progress} />
    </div>
  );
};

export default DirectFileUpload;
