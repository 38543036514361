import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "kathrin-schwartz",
  firstname: "Kathrin",
  lastname: "Schwartz",
  title: "Rechtsanwältin",
  description: "",
  areas: [Area.MIETUNDWOHNUNGSEIGENTUMSRECHT],
  subAreas: [
    Area.MIETUNDWOHNUNGSEIGENTUMSRECHT,
    Area.GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT,
    Area.ERBRECHT,
  ],
  phone: "030 / 75 79 64 0",
  mail: "kathrin.schwartz@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1981 in Berlin",
    "Studium in Berlin",
    "Referendariat beim Oberlandesgericht Düsseldorf",
    "Masterstudiengang Informationsrecht an der Heinrich-Heine-Universität Düsseldorf",
    "Rechtsanwältin seit 2011 in Berlin",
  ],
  memberships: [
    'Arbeitsgemeinschaft "Mietrecht und Immobilien" des Deutschen Anwaltsvereins',
  ],
};

export default data;
