import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.TRANSPORTRECHT,
  content: [
    "Wir beraten Transport-, Speditions- und Logistikunternehmen im In- und Ausland in allen allgemeinen Fracht- (HGB) und grenzüberschreitenden Straßentransportangelegenheiten (CMR), insbesondere bei",
    [
      "Geltendmachung und Abwehr von Frachtansprüchen",
      "Geltendmachung und Abwehr von Schäden aus einem Verkehrsunfall des Transportfahrzeuges",
      "Geltendmachung und Abwicklung von Transport- und Folgeschäden",
      "Lagerhaltung, Güterumschlag, Logistik",
      "Lademittel, Gefahrguttransport",
      "Transportversicherungsrecht",
      "Erstellung und Prüfung von Speditions- und Logistikverträgen",
      "ADSp",
      "Abwehr von Arbeits- und Lenkzeitüberschreitungen",
      "Abwehr von Verstößen gegen Zollvorschriften, Mautgesetze und Verkehrssteuern",
    ],
  ],
  bullets: [
    "Vertragsgestaltung nach HGB, CMR, ADSp",
    "Transportschadensabwicklung",
    "Lenkzeitüberschreitungen",
    "Lademittel",
    "Transport",
    "Spedition",
    "Lagerei",
  ],
  more: [],
};

export default data;
