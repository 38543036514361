import { useState } from "react";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import DirectFileUpload, {
  OnCallbackFile,
  UPLOAD_TYPES,
} from "../../UI/DirectFileUpload/DirectFileUpload";
import Input from "../../UI/Input/Input";
import Form from "../../UI/Form/Form";
import { fileMaxFileSizeInBytes, fileMimeTypes } from "../../../utility";
import Currency from "../../UI/Input/Currency";
import Button from "../../UI/Button/Button";
import styled from "styled-components";
import Textarea from "../../UI/Textarea/Textarea";

type Contact = {
  firstname?: string;
  lastname?: string;
  mail?: string;
  phone?: string;
  subject?: string;
  text?: string;
  fileKeys: string[];
};

const Wrapper = styled.div`
  width: calc(50% - 15px / 2);
`;

const ContactForm: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const [data, setData] = useState<Contact>({
    fileKeys: [],
  });

  const submit = () => {
    alert("submit");
  };

  const set = (key: keyof Contact) => (value: string) =>
    setData({ ...data, [key]: value });

  const onFile = (files: OnCallbackFile[]) => {
    setData({
      ...data,
      fileKeys: data.fileKeys.concat(files.map((f) => f.key)),
    });
  };

  return (
    <Form onSubmit={submit}>
      <div className="flex gap-5 mt-10">
        <div className="flex flex-wrap gap-[15px] w-full">
          <Wrapper>
            <Input
              required
              value={data.firstname}
              placeholder={t.generic.firstname}
              onChange={set("firstname")}
            />
          </Wrapper>
          <Wrapper>
            <Input
              required
              value={data.lastname}
              placeholder={t.generic.lastname}
              onChange={set("lastname")}
            />
          </Wrapper>
          <Wrapper>
            <Input
              required
              type="email"
              value={data.mail}
              placeholder={t.generic.mail}
              onChange={set("mail")}
            />
          </Wrapper>
          <Wrapper>
            <Input
              required
              value={data.phone}
              placeholder={t.generic.phone}
              onChange={set("phone")}
            />
          </Wrapper>
          <Input
            required
            value={data.subject}
            placeholder={t.generic.subject}
            onChange={set("subject")}
          />
          <Textarea
            required
            value={data.text}
            placeholder={t.generic.text}
            onChange={set("text")}
          />
        </div>
        {/* <div className="flex flex-col gap-1 w-1/2">
          <DirectFileUpload
            uploadType={UPLOAD_TYPES.APPLICATION}
            allowedMimeTypes={fileMimeTypes}
            maxFileSizeInBytes={fileMaxFileSizeInBytes}
            onFilesCallback={onFile}
            multiple
          >
            <div>
              <div className="font-bold text-lg text-center text-gray">
                {t.career.openPositions.form.dragAndDropTitle}
              </div>
              <div>
                {t.career.openPositions.form.dragAndDropHint.map((h, i) => (
                  <div className="text-sm text-center" key={i}>
                    {h}
                  </div>
                ))}
              </div>
            </div>
          </DirectFileUpload>
        </div> */}
      </div>
      <div className="mt-5 w-fit mx-auto">
        <Button
          text={t.contact.section.cta}
          type="submit"
          className="w-full uppercase !px-20"
        />
      </div>
    </Form>
  );
};

export default ContactForm;
