export const translation = {
  projectName: "Wilms - Rechtsanwälte",

  home: {
    hero: {
      subtitle: ["Herzlich", "Willkommen"],
      title: ["Unfall?"],
      subline: "Nicht deine Schuld.",
      cta: "Mehr erfahren",
    },
    intro: {
      headlinePrefix: "Über unsere",
      headline: " Kanzlei",
      subline: "Hintergrundinfos",
      textPrefix: "Herzlich willkommen auf den Internetseiten ",
      text: "der WILMS Rechtsanwälte. Seit über 20 Jahren unterstützen wir in unserer multidisziplinären Kanzlei kleine und mittelständische Unternehmen und Unternehmer, Freiberufler und Verbraucher bei der Wahrung und Wahrnehmung ihrer Rechte. Wir beraten, um Lösungen zu finden und Streit vorzubeugen, und vertreten unsere Mandanten, wenn es bereits zum Streit gekommen ist.",
    },
    fachgebiete: {
      headlinePrefix: "Unsere",
      headline: " Fachgebiete",
      subline: "Übersicht",
      textPrefix: "Herzlich willkommen auf den Internetseiten ",
      text: "der WILMS Rechtsanwälte. Seit über 20 Jahren unterstützen wir in unserer multidisziplinären Kanzlei kleine und mittelständische Unternehmen und Unternehmer, Freiberufler und Verbraucher bei der Wahrung und Wahrnehmung ihrer Rechte. Wir beraten, um Lösungen zu finden und Streit vorzubeugen, und vertreten unsere Mandanten, wenn es bereits zum Streit gekommen ist.",
    },
    team: {
      headlinePrefix: "Unser",
      headline: " Team",
      subline: "Anwälte",
    },
    checklist: {
      headlinePrefix: "Checkliste für Ihre",
      headline: " Kontaktaufnahme",
      subline: "Übersicht",
      items: [
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet.",
      ],
    },
  },

  contact: {
    hero: {
      subtitle: [""],
      title: ["Kontakt"],
    },
    intro: {
      headlinePrefix: "Wilms",
      headline: " Rechtsanwälte",
      subline: "Anwaltskanzlei",
    },
    openHoursText: "Sie erreichen uns zu folgenden Bürozeiten:",
    openHours: [
      "Montag – Donnerstag 9.00 – 17.00 Uhr",
      "Freitag 9.00 – 15.00 Uhr",
    ],
    section: {
      headline: "Kontaktformular",
      cta: "Senden",
    },
    quote:
      "„Es hat keinen Sinn zu tun, was einem gefällt - man muss Gefallen finden an dem, was man tut.”",
  },

  areas: {
    VERTRAGSRECHT: "Vertragsrecht",
    VERKEHRSRECHT: "Verkehrsrecht",
    REISERECHT: "Reiserecht",
    MEDIZINRECHT: "Medizinrecht",
    ARBEITSRECHT: "Arbeitsrecht",
    FAMILIENRECHT: "Familienrecht",
    ITUNDDATENRECHT: "IT- und Datenrecht",
    MIETUNDWOHNUNGSEIGENTUMSRECHT: "Miet- und Wohnungseigentumsrecht",
    GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT:
      "Gewerblicher Rechtsschutz und Urheberrecht",
    ERBRECHT: "Erbrecht",
    TRANSPORTRECHT: "Transportrecht",
    HANDELUNDGESELLSCHAFTSRECHT: "Handel- und Gesellschaftsrecht",
    ALLGEMEINESZIVILRECHT: "Allgemeines Zivilrecht",
    LLMINFORMATIONSRECHT: "LL.M. (Informationsrecht)",
  },

  areasSlider: {
    itemLabel: "Themengebiet",
  },

  lawyersView: {
    hero: {
      subtitle: ["Übersicht"],
      title: ["Rechtsanwälte"],
    },
    contact: {
      title: "Kontakt",
      form: {
        label: "Kontaktformular",
        link: "/kontakt",
      },
    },
    vita: {
      title: "Vita",
    },
    memberships: {
      title: "Mitgliedschaften",
    },
    areas: {
      prefix: "Seine",
      text: "Fachgebiete",
      subline: "Übersicht",
    },
  },

  lawyers: {
    hero: {
      subtitle: ["Übersicht"],
      title: ["Rechtsanwälte"],
    },
    intro: {
      headlinePrefix: "Über unsere",
      headline: " Kanzlei",
      subline: "Hintergrundinfos",
      textPrefix: "Herzlich willkommen auf den Internetseiten ",
      text: "der WILMS Rechtsanwälte. Seit über 20 Jahren unterstützen wir in unserer multidisziplinären Kanzlei kleine und mittelständische Unternehmen und Unternehmer, Freiberufler und Verbraucher bei der Wahrung und Wahrnehmung ihrer Rechte. Wir beraten, um Lösungen zu finden und Streit vorzubeugen, und vertreten unsere Mandanten, wenn es bereits zum Streit gekommen ist.",
    },
    headline: "Unsere Anwälte",
    areaPrefix: "Fachanwalt für ",
    contactData: "Kontaktdaten",
    showAllCTA: "Alle Anwälte",
  },

  subjectAreas: {
    hero: {
      subtitle: [
        "Seit über 20 Jahren sind wir in diesen Rechtsbereichen für Sie tätig.",
      ],
      title: ["Fachgebiete"],
    },
    intro: {
      headlinePrefix: "Unsere",
      headline: " Fachgebiete",
      subline: "Spezialisierungen",
      text: "Seit über 20 Jahren unterstützen wir in unserer multidisziplinären Kanzlei kleine und mittelständische Unternehmen und Unternehmer, Freiberufler und Verbraucher bei der Wahrung und Wahrnehmung ihrer Rechte. Wir beraten, um Lösungen zu finden und Streit vorzubeugen, und vertreten unsere Mandanten, wenn es bereits zum Streit gekommen ist.",
    },
    headline: "Unsere Anwälte",
    areaPrefix: "Fachanwalt für ",
    showAllCTA: "Alle Anwälte",
  },

  subjectAreaView: {
    hero: {
      subtitle: ["Fachgebiet"],
    },
    bullets: {
      title: "Stichpunkte",
    },
    more: {
      title: "Weitere Informationen",
    },
    lawyersList: {
      headlinePrefix: "Rechtsanwälte mit diesem",
      headline: " Fachgebiet",
      subline: "Übersicht",
    },
    areaOverview: {
      headlinePrefix: "Unsere",
      headline: " Fachgebiete",
      subline: "Übersicht",
    },
  },

  kanzlei: {
    hero: {
      subtitle: ["Übersicht"],
      title: ["Kanzlei"],
    },
    intro: {
      headlinePrefix: "Über unsere",
      headline: " Kanzlei",
      subline: "Hintergrundinfos",
      text: 'Unsere Kanzlei liegt in Berlin - Tempelhof, unweit des ehemaligen "Zentralflughafens". In zwei alten Gutshäusern aus dem 19. Jahrhundert mischen sich Tradition und Moderne. Machen Sie sich selbst ein Bild, am besten persönlich.',
    },
  },

  blog: {
    hero: {
      subtitle: [
        "Seit über 20 Jahren sind wir in diesen Rechtsbereichen für Sie tätig.",
      ],
      title: ["Blog"],
    },
    intro: {
      headlinePrefix: "Unsere",
      headline: " Blog",
      subline: "Alles zum Thema Recht",
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
  },

  career: {
    hero: {
      subtitle: "Karriere",
      title: ["Viele Menschen", "mit einem Ziel"],
      subline: "Werden Sie Teil von Wilms Rechtsanwälte",
    },
    boxes: [
      {
        title: "Team",
        text: "In unserer Kanzlei ist der Zusammenhalt von großer Bedeutung, um unsere Ziele zu erreichen und unsere Mandanten bestmöglich zu unterstützen.  Durch unsere enge Verbundenheit schaffen wir gemeinsam eine starke Grundlage für unseren Erfolg.",
      },
      {
        title: "AUSGLEICH",
        text: "Wir legen Wert auf die Balance zwischen Beruf und Privatleben. Jeder unserer Mitarbeitenden hat individuelle Bedürfnisse und Umstände, die wir respektieren und unterstützen möchten. Denn nur wenn im Privatleben ein Ausgleich gefunden wird, kann berufliches Potenzial voll entfaltet werden.",
      },
      {
        title: "Austausch",
        text: "Wir möchten unseren Mitarbeitenden helfen, sich auf vielfältige Weise weiterzuentwickeln, damit sie unsere Mandanten bestmöglich unterstützen können. Wir glauben daran, dass unterschiedliche Perspektiven und Ideen dabei helfen, eine enge Verbindung zu unseren Mandanten aufzubauen.",
      },
    ],
    reasons: {
      headline: "8 Gründe Teammitglied zu werden",
      items: [
        {
          title: "Modernes Arbeitsumfeld",
          text: "Bei uns erwartet Sie ein modernes Arbeitsumfeld, das mit den neuesten Technologien und Trends ausgestattet ist.",
        },
        {
          title: "Effiziente Arbeitsprozesse",
          text: "Wir setzen auf digitale Tools und Automatisierung, um unsere Arbeitsabläufe effizienter und produktiver zu gestalten.",
        },
        {
          title: "Flexibilität und Freiheit",
          text: "Wir bieten Ihnen flexible Arbeitszeiten und die Möglichkeit, eigenverantwortlich zu arbeiten. Sie können Ihre Arbeit nach Ihren individuellen Bedürfnissen gestalten.",
        },
        {
          title: "Kreativer Spirit",
          text: "Bei uns wird Kreativität großgeschrieben! Wir ermutigen Sie, neue Ideen einzubringen und unkonventionelle Lösungsansätze zu entwickeln.",
        },
        {
          title: "Beigeisterung für Technologie",
          text: "Wir sind begeistert von Technologie und immer auf dem neuesten Stand. Sie werden die Möglichkeit haben, mit modernsten Tools und Technologien zu arbeiten und Ihre Fähigkeiten weiterzuentwickeln.",
        },
        {
          title: "Gemütlichkeit am Arbeitsplatz",
          text: "Unser moderner Arbeitsplatz bietet nicht nur technische Raffinessen, sondern auch eine gemütliche Atmosphäre. Sie können sich bei uns wohlfühlen und entspannt arbeiten.",
        },
        {
          title: "Sicherheit in der Zukunft",
          text: "Durch unsere Fokussierung auf Innovation und technologischen Fortschritt bieten wir Ihnen langfristige Perspektiven und Sicherheit in einer sich wandelnden Arbeitswelt.",
        },
        {
          title: "Teamgeist und Spaß",
          text: "Bei uns steht der Teamgeist im Vordergrund. Wir organisieren regelmäßige Teamevents und schaffen eine angenehme Arbeitsatmosphäre, in der Spaß und Zusammenarbeit im Fokus stehen.",
        },
      ],
    },
    quote: {
      text: "„Es hat keinen Sinn zu tun, was einem gefällt - man muss Gefallen finden an dem, was man tut.”",
    },
    contact: {
      headline: "Deine Ansprechpartner",
      people: [
        {
          name: "Friedrich Schulze",
          title: "Rechtsanwalt",
          mail: "karriere@wilms.eu",
        },
        {
          name: "Kathrin Schwartz",
          title: "Rechtsanwältin",
          subtitle: "LL.M. (Informationsrecht)",
          mail: "karriere@wilms.eu",
        },
      ],
    },
    openPositions: {
      headline: "Unsere Offenene Stellen",
      tasks: "Ihre Aufgaben bei uns:",
      expectations: "Uns ist wichtig:",
      offerings: "Es erwartet Sie:",
      form: {
        entryDate: "Wunsch-Eintrittsdatum",
        salaryExpectation: "Gehaltsvorstellung",
        dragAndDropTitle: "Drag & Drop",
        dragAndDropHint: [
          "Eigene Dokumente hier hochladen",
          "(Lebenslauf, Zeugnisse, o.ä.)",
        ],
        maxFileSizeExceededToast:
          "Ihre Datei überschreitet die zulässige Größe von 50 MB.",
        cta: "Bewerbung senden",
        error: "Beim Versand Ihrer Bewerbung ist ein Fehler aufgetreten.",
        success:
          "Wir haben Ihre Bewerbung erhalten und melden uns schnellstmöglich.",
      },
      items: [
        {
          title: "RECHTSANWALTSFACHANGESTELLTE/R (M/W/D)",
          tasks:
            "Sie erledigen die Korrespondenz mit Gerichten, Gegnern und Mandanten und sind für diese immer erster Anlaufpunkt. Zudem übernehmen Sie organisatorische Aufgaben wie die Eingangspostbearbeitung, das Fristenmanagement und die Koordinierung von Terminen.",
          expectations:
            "Wir geben Aufgaben und Richtung vor, legen aber großen Wert auf eigenständiges Arbeiten. Wir lassen Raum, eigene Ideen einzubringen und diese eigenverantwortlich umzusetzen. Sorgfältiges Arbeiten ist in unserem Beruf unverzichtbar, ebenso wie der respektvolle Umgang miteinander.",
          offerings:
            "Unser Team ist eine gute Mischung aus Jung & (Mittel)alt, es vereint jugendlichen Schwung mit ausreichend Erfahrung. Über die letzten 25 Jahre ist ein großer Mandantenstamm gewachsen, der uns zuverlässig vor immer wieder neue Aufgaben stellt. Das, was wir tun, machen wir gern. Wenn Sie mitmachen möchten, freuen wir uns auf Sie.",
        },
      ],
      success: {
        form: "Ihre Bewerbung wurde erfolgreich verschickt. Wir melden uns schnellstmöglich bei Ihnen.",
      },
      error: {
        form: "Aufgrund eines technischen Fehlers konnte Ihre Bewerbung nicht verschickt werden.",
      },
    },
  },

  header: {
    navigation: {
      default: [
        {
          name: "Start",
          link: "/",
        },
        {
          name: "Fachgebiete",
          link: "/fachgebiete",
        },
        {
          name: "Rechtsanwälte",
          link: "/rechtsanwaelte",
        },
        {
          name: "Kanzlei",
          link: "/kanzlei",
        },
        {
          name: "Kontakt",
          link: "/kontakt",
        },
        {
          name: "Blog",
          link: "/blog",
        },
      ],
      member: [
        {
          name: "Projekte",
          link: "/dashboard/files",
        },
        {
          name: "Zeiterfassung",
          link: "/dashboard/timetracking",
          requiredPermission: "settingsTimetacker",
        },
        {
          name: "Einstellungen",
          link: "/dashboard/settings",
          requiredPermission: "settingsAccess",
        },
      ],
      isLoggedIn: {
        name: "Abmelden",
      },
    },
  },

  dashboard: {
    title: "Dashboard",
  },

  login: {
    headline: "Anmelden",
    cta: "Anmelden",
    error: "Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.",
  },

  settings: {
    title: "Einstellungen",
    memberManagement: {
      title: "Mitarbeiter",
      upsert: {
        titleCreate: "Mitarbeiter hinzufügen",
        titleUpdate: "Mitarbeiter bearbeiten",
        cta: "Mitarbeiter hinzufügen",
        success: "Mitarbeiter wurde erfolgreich gespeichert",
        error: {
          upsert: "Mitarbeiter konnte nicht gespeichert werden",
          remove: "Mitarbeiter konnte nicht gelöscht werden",
          loading: "Mitarbeiter konnten nicht geladen werden",
        },
        edit: "Mitarbeiter bearbeiten",
        remove: {
          title: "Mitarbeiter löschen",
          hint: "Sind Sie sicher, dass Sie diesen Mitarbeiter löschen möchten?",
          error: "Mitarbeiter konnte nicht gelöscht werden",
        },
      },
    },
  },

  footer: {
    links: "Links",
    legalLinks: [
      { name: "Impressum", link: "https://www.wilms.eu/impressum" },
      { name: "Datenschutz", link: "https://www.wilms.eu/datenschutz" },
    ],
  },

  generic: {
    company: {
      name: "Wilms Rechtsanwälte",
      street: "Alt-Tempelhof 37/41",
      city: "12103 Berlin",
      phone: "+49 30 75 79 64 0",
      fax: "+49 30 75 79 64 8",
    },
    settings: "Einstellungen",
    hourPrefix: "Stunden: ",
    hourSuffix: " Stunden",
    squareMeterSuffix: " m²",
    equals: " = ",
    timestampPrefix: "Erstellt am: ",
    phoneShortPrefix: "T ",
    faxShortPrefix: "T ",
    phonePrefix: "Telefon: ",
    mailPrefix: "E-Mail: ",
    websitePrefix: "Webseite: ",
    employeeSuffix: " Mitarbeiter",
    subject: "Betreff",
    text: "Nachricht",
    salutation: "Anrede",
    name: "Name",
    firstname: "Vorname",
    lastname: "Nachname",
    birthday: "Geburtsdatum",
    personType: "Art",
    personTypePlaceholder: "Beirat, Geschäftsführer, ...",
    street: "Straße, Hausnummer",
    companyName: "Firmenname",
    club: "Verein (optional)",
    city: "PLZ, Ort",
    mail: "E-Mail-Adresse",
    identifier: "E-Mail-Adresse",
    message: "Ihre Anfrage...",
    phone: "Telefonnummer",
    password: "Passwort",
    passwordRepeat: "Passwort wiederholen",
    createdOn: "Erstellt am",
    confirm: "Bestätigen",
    cancel: "Abbrechen",
    close: "Schließen",
    send: "Senden",
    error:
      "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.",
    acceptAGB: "Ich akzeptiere die AGB",
    percent: " %",
    kilometerSuffix: " km",
    upload: "Hochladen",
    login: "Anmelden",
    logout: "Abmelden",
    export: "Export",
    import: "Import",
    save: "Speichern",
  },

  UI: {
    removeConfirmation: {
      title: "Bestätigung",
      text: (itemName: string) => `Möchten Sie "${itemName}" wirklich löschen?`,
    },
    copyConfirmation: {
      title: "Kopieren",
      text: (itemName: string) => `${itemName}-Kopie umbenennen zu:`,
      initNameSuffix: "-Kopie",
    },
    customerSelector: {
      label: "Kundenauswahl",
    },
    projectSelector: {
      label: "Projekt wählen",
    },
    projectAreaSelector: {
      label: "Projektbereich wählen",
    },
    contactPersonManager: {
      title: "Kontaktpersonen",
      addTitle: "Kontaktperson hinzufügen",
      updateTitle: "Kontaktperson bearbeiten",
      empty: "Keine Kontaktpersonen vorhanden",
      add: "hinzufügen",
    },
    organizationUnitManager: {
      title: "Organisationseinheiten",
      addTitle: "Organisationseinheit hinzufügen",
      employeeAmountSuffixShort: " MA",
      employeeAmountSuffix: " Mitarbeiter",
      updateTitle: "Organisationseinheit bearbeiten",
      empty: "Keine Organisationseinheiten vorhanden",
      add: "hinzufügen",
    },
    simpleList: {
      search: "Suche",
      resultAmountSuffixPlural: " Ergebnisse",
      resultAmountSuffixSingular: " Ergebnis",
      noData: "Keine Daten vorhanden",
    },
    multiList: {
      search: "Suche",
      resultAmountSuffix: " Ergebnisse",
      noData: "Keine Daten vorhanden",
      cta: "Ausführen",
      cancelCTA: "Abbrechen",
      option: {
        EXPORT: "Export",
        PROCESS: "Verarbeiten",
        STOP: "Stoppen",
      },
      export: {
        title: "Export",
        identifier: "Kennung",
      },
      error: "Aktion konnte nicht ausgeführt werden",
    },
    csvImport: {
      title: "Import",
      startCTA: "Import starten",
      loading: "Daten werden imporiert...",
      error: "Der Import ist fehlgeschlagen.",
    },
    dropdown: {
      emptySelection: "Bitte wählen",
      search: "Suche",
    },
    selectableTagList: {
      showMore: "Mehr anzeigen",
      showLess: "Weniger",
    },
    textarea: {
      max: {
        prefix: "maximal ",
        suffix: " Zeichen",
      },
    },
    dnd: {
      text: "Bewerbung anhängen",
      hint: "(Erlaubt ist eine PDF-Datei bis zu 25 MB)",
      selectedPrefix: "Gewählte PDF: ",
      error: {
        size: "Maximale Dateigröße überschritten",
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: "Passwörter stimmen nicht überein",
    passwordReset: "Passwort wurde erfolgreich geändert",
    save: "Ändern",
    password: "Passwort",
    passwordRepeat: "Passwort wiederholen",
    title: "Passwort ändern",
  },
};

export const langKey = "DE";
