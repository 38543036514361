import styled from "styled-components";

export const StyledBoxes = styled.div`
  display: flex;
  gap: 15px;
  transform: translateY(-50%);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    transform: translateY(-15%);
  }
`;
