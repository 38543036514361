import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "diedrich-wilms",
  firstname: "Diedrich",
  lastname: "Wilms",
  title: "Rechtsanwalt",
  description: "",
  areas: [Area.MEDIZINRECHT],
  subAreas: [
    Area.MEDIZINRECHT,
    Area.TRANSPORTRECHT,
    Area.FAMILIENRECHT,
    Area.ERBRECHT,
  ],
  phone: "030 / 75 79 64 0",
  mail: "diedrich.wilms@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1969 in Dortmund",
    "Studium in Marburg und Berlin",
    "Referendariat beim Kammergericht Berlin",
    "1993/94 Leiter des Rechtsamtes der Stadt Storkow",
    "Rechtsanwalt seit 1996 in Berlin",
    "Fachanwalt für Medizinrecht seit 2009",
  ],
  memberships: [
    "Deutsche Gesellschaft für Kassenarztrecht",
    "Anwälte für Ärzte e.V.",
    "Arbeitsgemeinschaft Anwaltsmanagement im Deutschen Anwaltverein",
    "Europäische Juristenvereinigung United Legal Network",
    "Europäisches Wirtschaftsforum e.V. Berlin",
    "Deutsche Gesellschaft für Transportrecht, Hamburg",
    "SALITARIS Healthcare Excellence Network",
  ],
};

export default data;
