import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "bgh-auch-unternehmer-erhalten-bearbeitungsentgelt-bei-darlehen-zurueck",
  title:
    "BGH: Auch Unternehmer erhalten Bearbeitungsentgelt bei Darlehen zurück",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "",
  date: new Date("2017-10-10"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Banken haben in Darlehensverträgen jahrelang laufzeitunabhängige Bearbeitungsentgelte berechnet. Diese müssen sie nun zurückzahlen. Der BGH hatte das für Verbraucherdarlehen bereits entschieden. Nun hat er in zwei aktuellen Verfahren klargestellt, dass die Bearbeitungsentgelte auch gegenüber Unternehmern unzulässig sind (Urteile vom 4. Juli 2017 zu XI ZR 562/15 und XI ZR 233/16).",
        "Hat eine Bank ihrem Kunden in einer Formularklausel eine laufzeitunabhängige „Bearbeitungsgebühr“ oder ein „Bearbeitungsentgelt“ auferlegt, so sieht der BGH hierin eine unangemessene Benachteiligung des Kunden. Die Banken haben solche Bearbeitungsentgelte nun zurückzuzahlen.",
        "Wir haben bereits in einigen Fällen eine Rückzahlung solcher Entgelte erwirkt und bieten unseren Mandanten an, deren Darlehensverträge ab dem Jahr 2014 daraufhin zu überprüfen, ob Rückforderungen begründet sein können. Ältere Forderungen wären verjährt. Da zum 31.12.2017 die Verjährung weiterer Forderungen droht, raten wir, schnell tätig zu werden.",
      ],
    },
  ],
};

export default data;
