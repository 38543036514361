import styled from "styled-components";
import { BlogEntry } from "../../hooks/useBlog/entries/types";
import Card from "./Card/Card";

type Props = {
  entries: BlogEntry[];
};

const Wrapper = styled.div`
  width: calc(100% / 4 - (15px * 3 / 4));
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2 - (15px / 2));
  }
`;

const List: React.FC<Props> = ({ entries }) => {
  if (!Array.isArray(entries)) return null;
  return (
    <div className="flex flex-wrap gap-[15px]">
      {entries.map((e) => (
        <Wrapper>
          <Card entry={e} />
        </Wrapper>
      ))}
    </div>
  );
};

export default List;
