import { ForwardedRef, forwardRef } from "react";
import { getDateInputFormat, getDateTimeInputFormat } from "../../../utility";
import moment from "moment";

export interface BaseInput
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  id?: string;
  type?: string;
  onChange: (value: any) => void;
  label?: string | boolean;
  placeholder?: string;
  className?: string;
  contentClassName?: string;
  required?: boolean;
  Icon?: any;
  value: any;
  autoComplete?: string;
  prefixText?: string;
  suffixText?: string;
  returnRawChangeEvent?: boolean;
  disabled?: boolean;
  hiddenDisabled?: boolean;
  onBlur?: () => void;
  invert?: boolean;
  invertLabel?: boolean;
  maxLength?: number;
}

const Input = forwardRef(
  (
    {
      id,
      type = "text",
      value,
      label,
      placeholder,
      onChange,
      required = false,
      className = "",
      contentClassName = "",
      autoComplete = "off",
      returnRawChangeEvent = false,
      disabled = false,
      hiddenDisabled = false,
      onBlur,
      invert = false,
      invertLabel = false,
      maxLength,
      onClick,
    }: BaseInput,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const prepValue = () => {
      if (!value) return "";
      if (type === "date") {
        if (typeof value === "object") {
          return getDateInputFormat(value);
        } else if (value.includes("T")) {
          return getDateInputFormat(new Date(value));
        }
        return value;
      } else if (type === "datetime-local") {
        if (typeof value === "object") {
          return getDateTimeInputFormat(value);
        } else if (value.includes("T")) {
          return moment.utc(value).format("YYYY-MM-DDTHH:mm");
        }
        return value;
      } else {
        return value;
      }
    };

    return (
      <div className={`w-full ${className}`}>
        {label && (
          <label
            htmlFor={id}
            className={
              invert || invertLabel ? "text-white text-sm" : "text-gray text-sm"
            }
          >
            {typeof label === "string" ? label : placeholder}
          </label>
        )}
        <input
          id={id}
          ref={ref}
          onBlur={onBlur}
          disabled={disabled || hiddenDisabled}
          required={required}
          type={type}
          value={prepValue()}
          onChange={(e) => onChange(returnRawChangeEvent ? e : e.target.value)}
          placeholder={placeholder}
          onClick={onClick}
          className={`${
            invert
              ? "border placeholder-lightGray text-white w-full py-1 px-3 bg-blue focus:outline-none focus:shadow-outline"
              : "placeholder-lightblue w-full py-1 px-3 bg-white text-blue focus:outline-none focus:shadow-outline"
          } ${contentClassName}`}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
      </div>
    );
  }
);

export default Input;
