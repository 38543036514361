import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "die-zahlungsverzugskuendigung-in-der-virus-krise",
  title: "Die Zahlungsverzugskündigung in der Virus-Krise",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "felix-freist",
  date: new Date("2020-07-06"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Am 27. März 2020 hatten wir über den vorübergehenden Ausschluss der Kündigungsmöglichkeit des Vermieters wegen rückständiger Mieten berichtet; s. unten. Danach war im Gesetz zur Abmilderung der Folgen der Covid-19-Pandemie im Zivil-, Insolvenz- und Strafverfahrensrecht bestimmt, dass der Vermieter dann nicht zur fristlosen Kündigung des Mietverhältnisses berechtigt ist, wenn der Kündigungsgrund in Mietrückständen besteht, die in der Zeit vom 01.04.2020 bis 31.06.2020 aufgrund der Covid-19-Pandemie entstanden sind.",
        "Diese Kündigungsschutzvorschrift ist zum 1. Juli 2020 ausgelaufen. Der vorübergehende Kündigungsauschluss ist nicht verlängert worden. Kurz gefasst, gilt somit folgendes:",
      ],
    },
    {
      type: ContentSectionType.ENUMERATION,
      content: [
        "Mietschulden, die Covid-19-bedingt in der Zeit zwischen dem 01.04.2020 und 30.06.2020 entstanden sind, müssen spätestens bis zum 30. Juni 2022 nachbezahlt werden;",
        "ab dem 1. Juli 2020 muss die vertraglich geschuldete Miete wieder in voller Höhe bezahlt werden;",
        "Mietrückstände, die ab dem 1. Juli 2020 entstehen, können den Vermieter auch in Verbindung mit ggfs. älteren Mietrückständen aus der Zeit vor dem 01.04.2020 zur fristlosen Kündigung berechtigen;",
        "dies gilt Bereich der Wohnungsmiete bereits dann, wenn in zwei aufeinanderfolgenden Monaten ein Mietrückstand erreicht wird, der eine Monatsmiete übersteigt.",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: ["Rechtliche Fragen hierzu beantworten wir gern."],
    },
  ],
};

export default data;
