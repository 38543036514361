import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.FAMILIENRECHT,
  content: [
    "Das Familienrecht umfasst nicht nur das Eherecht, vor und nach der Ehe, sondern auch das Umgangsrecht, das Sorgerecht und die nichtehelichen Lebensgemeinschaften und eingetragene Lebenspartnerschaften. Daneben finden sich auch Bestimmungen zur Betreuung, Vormundschaft und Pflegschaft.",
    "Wir stehen Ihnen gestalterisch und beratend zur Seite ua. bei",
    [
      "der Gestaltung von Eheverträgen (Zugewinngemeinschaft, Gütertrennung, Gütergemeinschaft)",
      "der Durchführung der Scheidung, des Zugewinnausgleichs und des Versorgungsausgleiches",
      "der Durchsetzung des Umgangs-, Sorge- und -Besuchsrechts, sowie des Aufenthaltsbestimmungsrechts",
      "der Durchsetzung des Ehegattenunterhalts und Kindesunterhalt (Düsseldorfer Tabelle)",
    ],
  ],
  bullets: [
    "Gestaltung von Eheverträgen (Zugewinngemeinschaft, Gütertrennung, Gütergemeinschaft)",
    "Durchführung der Scheidung, des Zugewinnausgleichs und des Versorgungsausgleiches",
    "Durchsetzung des Umgangs-, Sorge- und -Besuchsrechts, sowie des Aufenthaltsbestimmungsrechts",
    "Durchsetzung des Ehegattenunterhalts und Kindesunterhalt (Düsseldorfer Tabelle)",
  ],
  more: [],
};

export default data;
