enum Routes {
  // generic routes
  GET_GOOGLE_PLACE_COORDINATES = 'GET_GOOGLE_PLACE_COORDINATES',
  GET_FILE_UPLOAD_PRESIGNED_URL = 'GET_FILE_UPLOAD_PRESIGNED_URL',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  GET_ACCESS_LIST = 'GET_ACCESS_LIST',
  GET_MEMBER_CONTEXT = 'GET_MEMBER_CONTEXT',
  CREATE_PROJECT = 'CREATE_PROJECT',

  // admin AI pdf routes
  CREATE_PDF_OCR_INTERPRETATION = 'CREATE_PDF_OCR_INTERPRETATION',
  CREATE_PDF_VISION_INTERPRETATION = 'CREATE_PDF_VISION_INTERPRETATION',
  RUN_PDF_INFERENCE = 'RUN_PDF_INFERENCE',
  RESET = 'RESET',

  // file explorer routes
  FILE_SYSTEM_LIST_ITEMS_WITH_CONTEXT = 'FILE_SYSTEM_LIST_ITEMS_WITH_CONTEXT',
  FILE_SYSTEM_INITIALIZE = 'FILE_SYSTEM_INITIALIZE',
  FILE_SYSTEM_CREATE_FOLDER = 'FILE_SYSTEM_CREATE_FOLDER',
  FILE_SYSTEM_REMOVE_FOLDER = 'FILE_SYSTEM_REMOVE_FOLDER',
  FILE_SYSTEM_GET_PRESIGNED_UPLOAD_URL = 'FILE_SYSTEM_GET_PRESIGNED_UPLOAD_URL',
  FILE_SYSTEM_ADD_FILES = 'FILE_SYSTEM_ADD_FILES',
  FILE_SYSTEM_REMOVE_FILE = 'FILE_SYSTEM_REMOVE_FILE',
  FILE_SYSTEM_GET_PRESIGNED_DOWNLOAD_URL = 'FILE_SYSTEM_GET_PRESIGNED_DOWNLOAD_URL',

  // timetracker routes
  TIMETRACKER_GET_ENTRIES = 'TIMETRACKER_GET_ENTRIES',
  TIMETRACKER_CREATE_ENTRY = 'TIMETRACKER_CREATE_ENTRY',
}

export default Routes
