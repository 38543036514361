import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.MEDIZINRECHT,
  content: [
    "Wir beraten und vertreten Ärzte bei der Verwirklichung ihres Niederlassungswunsches bzw. unterstützen bei der Gestaltung vertragsärztlicher Kooperationsformen, insbesondere bei",
    [
      "der Gründung von Medizinischen Versorgungszentren (MVZ)",
      "der Gründung oder dem Beitritt in eine Praxisgemeinschaft oder Berufsausübungsgemeinschaft (Gemeinschaftspraxis)",
      "Praxisübernahmen/ Praxisnachfolgen",
      "Kooperationen mit anderen Leistungserbringern",
    ],
    "Als Leistungserbringer für die Gesetzliche Krankenversicherung unterliegt der Vertragsarzt besonderen Regelungen, bei denen wir beratend und gestaltend zur Seite stehen, wie etwa bei Fragen zu",
    [
      "der Zulassung als Vertragsarzt,",
      "der Nachbesetzung des Vertragsarztsitzes, Vorbereitung und Umsetzung der Praxisaufgabe und/ oder Nachfolgeregelung",
      "der Anstellung von Ärzten",
      "der Nachbesetzung bestehender Arztstellen",
      "der Plausibilitätsprüfung,",
      "der Wirtschaftlichkeitsprüfung,",
      "der Abrechnung nach GOÄ und/ oder EBM",
      "vertragsärztlichen Disziplinarverfahren (Berufsordnung)",
      "der Abwehr von Arzthaftung.",
    ],
    "Nichtärztliche Leistungserbringer, wie z.B. Sanitätshäuser, beraten wir bei der Gestaltung von Kooperationen mit anderen Leistungserbringern der Gesetzlichen Krankenversicherung unter Beachtung der geltenden berufs- und sozialrechtlichen Regelungen, des § 299 a/b StGB und des § 128 SGB V.",
    "Wir beraten zum Heilmittelwerberecht.",
    "Als Patient beraten und unterstützen wir Sie u.a. bei der Durchsetzung von Versorgungsansprüchen, z.B. in Bereichen",
    [
      "der Hilfsmittelversorgung, z.B. Prothesenversorgung",
      "der Kostenerstattung bei Inanspruchnahme neuer Untersuchungs- und Behandlungsmethoden (NUB), z.B. Genexpressionsanalysen",
    ],
  ],
  bullets: [
    "Arzthaftungsrecht",
    "Kooperationen im Gesundheitswesen",
    "Vertragsarztrecht",
    "Berufsrecht",
    "Recht der Medizinischen",
    "Versorgungszentren (MVZ)",
  ],
  more: [
    {
      title: "Bundesministerium für Gesundheit",
      href: "https://www.bundesgesundheitsministerium.de/",
    },
    { title: "Bundesärztekammer", href: "https://www.bundesaerztekammer.de/" },
    {
      title: "Kassenärztliche Bundesvereinigung",
      href: "https://www.kbv.de/html/",
    },
    { title: "Bundeszahnärztekammer", href: "https://www.bzaek.de/" },
    {
      title: "Bundessozialgericht",
      href: "https://www.bsg.bund.de/cln_108/DE/Home/homepage__node.html?__nnn=true",
    },
  ],
};

export default data;
