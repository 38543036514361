import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "anspruch-auf-aktivitaetsprothese-als-zweitversorgung",
  title: "Anspruch auf Aktivitätsprothese als Zweitversorgung?",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "diedrich-wilms",
  date: new Date("2020-04-15"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "„Ein Versicherter kann von der Krankenkasse nicht die Versorgung mit einer Sportprothese beanspruchen, um seinen sportlichen Aktivitäten in der Freizeit noch besser nachgehen zu können“ – so lautete bislang der Leitsatz des BSG, den es mit Entscheidung vom 21. März 2013 (B 3 KR 3/12 R) aufgestellt hatte und der seitdem von den Kostenträgern genutzt wird, um dem Wunsch des Versicherten nach einer Zweitversorgung im Interesse einer erhöhten Aktivität einen Riegel vor zu schieben.",
        "Hier hat das Bayerische Landessozialgericht in einer Entscheidung vom 30. April 2019 (L 4 KR 339/18) nun eine andere Position eingenommen.",
        "Das LSG hält eine Zweitversorgung aus Aktivitätsgründen nicht für grundsätzlich ausgeschlossen, sondern hält hier eine Einzelfallprüfung für nötig, die durchaus zur Beanspruchbarkeit eine solchen Zweitversorgung, wie sie das BSG für den Bereich der wasserfesten Versorgung bereits anerkannt hat, führen kann.",
        "Das LSG stützt dies auf zwei nebeneinander greifende Begründungen.",
        "Zum einen meint das LSG -und sieht sich dabei im Einklang mit der Entscheidung des BSG-, dass jedenfalls dann ein Anspruch auf eine sportprothetische Versorgung nicht ausgeschlossen ist, wenn die Primärversorgung keine sportliche Betätigung ermöglicht. Hatte das BSG, das einen Fall eines einseitig Unterschenkelamputierten zu beurteilen hatte, noch angenommen, dass die dort vorhandene Primärversorgung ausreichend sei, weil mit ihr Schwimmen, Fitnessstudio, Radfahren, Wandern, Tischtennis und Sitzrad möglich waren, hatte das LSG nun den Fall einer Versicherten zu beurteilen, der beide Beine ab dem distalen Oberschenkeldrittel fehlten und deren Ellenbogen und Hände schwere Funktionsstörungen auswiesen. In diesem Fall war lediglich Liegeradfahren, und auch das nur eingeschränkt, ohne weitere Sport- bzw. Aktivitätsversorgung möglich. Dies hielt das LSG nicht für hinreichend, um damit einen Anspruch auf entsprechende Zweitversorgung auszuschließen, und bewilligte diese der Klägerin.",
        "Und zum anderen weist das LSG auf den seit der Entscheidung des BSG geänderten Behinderungsbegriff hin, auf den es für seine Anspruchsprüfung (§ 33 Abs. 1 Satz 1 Alt 2 bzw. 3 SGB V) ankam.",
        "Definiert ist der Behinderungsbegriff in § 2 Abs. 1 SGB IX. Seit Wirksamwerden des Bundesteilhabegesetzes am 1.1.2018 stehen hier nicht mehr die wirklichen oder vermeintlichen gesundheitlichen Defizite im Vordergrund, sondern vielmehr das Recht auf gleichberechtigte Teilhabe. Die Möglichkeiten einer individuellen und den persönlichen Wünschen entsprechenden Lebensplanung und -gestaltung sollen unter Berücksichtigung des Sozialraumes und der individuellen Bedürfnisse gestärkt werden (so auch BSG, 15.3.2018, B 3 KR 18/17 R).",
        "Vor diesen Hintergrund will das LSG den individuellen Wünschen des Versicherten nach Sport, Bewegung und Aktivität ein größeres Gewicht beimessen, als es noch zu Zeiten der eingangs zitierten grundsätzlichen Entscheidung des BSG gesetzlich vorgegeben war, und kommt so in dem von ihm zu entscheidenden Fall nach individueller Prüfung und Feststellung dieser Wünsche zu einem entsprechenden Anspruch auf Versorgung mit einer Sportprothese.",
        "Die Grenze meint das LSG in der Förderung des Leistungssports zu erkennen, die nicht der GKV unterfalle.",
        "Zusammenfassend lässt sich die These aufstellen, dass mit der Wertung des LSG die Versorgung einer aktivitäts- oder sportprothetischen Versorgung nicht mehr durch Freizeit- oder Vereinssport grundsätzlich ausgeschlossen wird, sondern erst mit der Förderung des Leistungssports. Solange ein Versicherter individuell eine Aktivität ausüben kann und möchte, die ihm mit seiner Primärversorgung nicht zugänglich ist, ist ein Anspruch auf Versorgung mit einer entsprechenden Aktivitätsversorgung denkbar.",
        "Rechtliche Fragen hierzu beantworten wir gern.",
      ],
    },
  ],
};

export default data;
