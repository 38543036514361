import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import "./Global.Styled";
import Home from "./components/Home/Home";
import Career from "./components/Career/Career";
import Lawyers from "./components/Lawyers/Lawyers";
import LawyerView from "./components/Lawyers/LawyerView/LawyerView";
import SubjectAreas from "./components/SubjectAreas/SubjectAreas";
import SubjectAreaView from "./components/SubjectAreas/SubjectAreaView/SubjectAreaView";
import Kanzlei from "./components/Kanzlei/Kanzlei";
import Contact from "./components/Contact/Contact";
import Blog from "./components/Blog/Blog";
import BlogView from "./components/Blog/BlogView/BlogView";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* Home */}
            <Route index element={<Home />} />
            <Route path={"karriere"} element={<Career />} />

            {/* Rechtsanwalte */}
            <Route path={"rechtsanwaelte"} element={<Lawyers />} />
            <Route path={"rechtsanwaelte/:id"} element={<LawyerView />} />

            {/* Fachgebiete */}
            <Route path={"fachgebiete"} element={<SubjectAreas />} />
            <Route path={"fachgebiete/:areaid"} element={<SubjectAreaView />} />

            {/* Kanzlei */}
            <Route path={"kanzlei"} element={<Kanzlei />} />

            {/* Kontakt */}
            <Route path={"kontakt"} element={<Contact />} />

            {/* Blog */}
            <Route path={"blog"} element={<Blog />} />
            <Route path={"blog/:id"} element={<BlogView />} />

            {/* fallback routing */}
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
