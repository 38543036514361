import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.ALLGEMEINESZIVILRECHT,
  content: [
    "Wir beraten und vertreten Sie umfassend im Bereich des allgemeinen Zivilrechts.",
    "Hierzu gehören u.a.",
    [
      "das Kauf- und Werkvertragsrecht",
      "das Dienstvertragsrecht",
      "das Leasingvertragsrecht",
      "die Durchsetzung von Gewährleistungsansprüchen, Garantieansprüchen",
      "die Beratung oder Abwehr von Rücktrittserklärungen vom Vertrag, Minderungsansprüchen und Schadensersatzansprüchen",
      "die Widerruflichkeit von Verträgen, insbesondere im Verbrauchsgüterkauf",
      "die Kündbarkeit von Verträgen",
      "die Überprüfung und Erstellung von Verträgen",
      "die Überprüfung und Erstellung von Allgemeinen Geschäftsbedingungen (AGB)",
    ],
  ],
  bullets: [
    "Kauf- und Werkvertragsrecht",
    "Dienstvertragsrecht",
    "Leasingvertragsrecht",
    "Durchsetzung von Gewährleistungsansprüchen, Garantieansprüchen",
    "Beratung oder Abwehr von Rücktrittserklärungen vom Vertrag, Minderungsansprüchen und Schadensersatzansprüchen",
    "Widerruflichkeit von Verträgen, insbesondere im Verbrauchsgüterkauf",
    "Kündbarkeit von Verträgen",
    "Überprüfung und Erstellung von Verträgen",
    "Überprüfung und Erstellung von Allgemeinen Geschäftsbedingungen (AGB)",
  ],
  more: [],
};

export default data;
