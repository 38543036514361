import { useSelector } from "react-redux";
import { StyledBoxes } from "./Boxes.Styled";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import Container from "../../UI/Container/Container";

const Boxes = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <StyledBoxes>
        {translation.career.boxes.map((b) => (
          <div className="bg-blue p-6 box-border flex-1" key={getUUID()}>
            <div className="text-white text-center text-xl font-bold">
              {b.title}
            </div>
            <div className="text-white text-center">{b.text}</div>
          </div>
        ))}
      </StyledBoxes>
    </Container>
  );
};

export default Boxes;
