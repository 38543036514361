import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "friedrich-schulze",
  firstname: "Friedrich",
  lastname: "Schulze",
  title: "Rechtsanwalt",
  description:
    "Friedrich Schulze ist seit 2005 als Rechtsanwalt tätig und hat sich auf das Arbeitsrecht spezialisiert.",
  areas: [Area.ARBEITSRECHT],
  subAreas: [
    Area.ARBEITSRECHT,
    Area.HANDELUNDGESELLSCHAFTSRECHT,
    Area.VERKEHRSRECHT,
  ],
  phone: "030 / 75 79 64 0",
  mail: "friedrich.schulze@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1969 in Berlin",
    "Studium in Berlin",
    "Referendariat beim Oberlandesgericht Rostock",
    "Rechtsanwalt seit 1996 in Berlin",
    "2001/02 Betriebsleiter in einem internationalen Logistikunternehmen in Halle/Westfalen",
    "von 2007 bis 2017 Geschäftsführer eines Personalüberlassungsunternehmens",
    "seit 2016 Geschäftsführer eines gemeinnützigen Trägers sozialer Einrichtungen",
  ],
  memberships: [
    "Europäische Juristenvereinigung United Legal Network",
    "Europäisches Wirtschaftsforum e.V. Berlin",
  ],
};

export default data;
