import { useSelector } from "react-redux";
import BlueGradientBG from "../../UI/BlueGradientBG/BlueGradientBG";
import Container from "../../UI/Container/Container";
import Headline from "../../UI/Headline/Headline";
import props from "../../../redux/props";
import People from "./People/People";

const Team: React.FC<{ limit?: number; slider?: boolean }> = ({
  limit,
  slider,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <BlueGradientBG className="shadow-2xl mt-28">
      <Container>
        <div className="py-16">
          <Headline
            invert
            prefix={t.home.team.headlinePrefix}
            text={t.home.team.headline}
            subline={t.home.team.subline}
          />
          <div className={slider === true && "mt-5"}>
            <People limit={limit} slider={slider} />
          </div>
        </div>
      </Container>
    </BlueGradientBG>
  );
};

export default Team;
