import { useParams } from "react-router-dom";
import {
  BlogEntry,
  ContentSectionType,
} from "../../hooks/useBlog/entries/types";
import { useEffect, useState } from "react";
import props from "../../../redux/props";
import { useSelector } from "react-redux";
import Layout from "../../UI/Layout/Layout";
import hero from "../../../assets/image/blog/hero.png";
import Hero from "../../UI/Hero/Hero";
import Container from "../../UI/Container/Container";
import useBlog from "../../hooks/useBlog/useBlog";
import { getPrettyDate } from "../../../utility";
import useLawyers from "../../hooks/useLawyers/useLawyers";

const BlogView = () => {
  const { id } = useParams();
  const t = useSelector((s) => s[props.TRANSLATION]);
  const [entry, setEntry] = useState<BlogEntry>(null);
  const [entries] = useBlog();
  const lawyers = useLawyers();

  useEffect(() => {
    if (!entry && entries) {
      const entry = entries.find((e) => e.id === id);
      if (entry) {
        setEntry(entry);
      } else {
        window.location.href = "/blog";
      }
    }
  }, [entry, entries, id]);

  const getLawyerName = (id: string) => {
    const lawyer = lawyers.find((l) => l.person.id === id);
    if (!lawyer) return "Wilms Rechtsanwälte";
    return `${lawyer.person.firstname} ${lawyer.person.lastname}`;
  };

  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          small
          subtitle={t.blog.hero.subtitle}
          title={t.blog.hero.title}
          fullWidthUnderline
        />
        {entry && (
          <Container>
            <div className="mt-14 pb-20">
              <img
                src={entry.imageURL}
                alt={entry.title}
                className="aspect-[16/5] object-cover object-center"
              />
              <div className="flex flex-col md:flex-row gap-2 mt-14">
                <div>{t.areas[entry.area]}</div>
                <div className="hidden md:block">{`|`}</div>
                <div>{getPrettyDate(entry.date)}</div>
                <div className="hidden md:block">{`|`}</div>
                {getLawyerName(entry.lawyerID)}
              </div>
              <div className="font-bold text-xl mt-3">{entry.title}</div>
              {entry.content.map((s) => {
                return (
                  <div>
                    {s.type === ContentSectionType.HEADLINE && (
                      <div className="font-bold mt-5">{s.content}</div>
                    )}
                    {s.type === ContentSectionType.TEXT && (
                      <>
                        {Array.isArray(s.content) ? (
                          s.content.map((c) => <div className="mt-5">{c}</div>)
                        ) : (
                          <div className="mt-5">{s.content}</div>
                        )}
                      </>
                    )}
                    {s.type === ContentSectionType.ENUMERATION && (
                      <ul className="mt-5 ml-5 list-disc">
                        {Array.isArray(s.content) ? (
                          s.content.map((c) => <li>{c}</li>)
                        ) : (
                          <li>{s.content}</li>
                        )}
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
          </Container>
        )}
      </div>
    </Layout>
  );
};

export default BlogView;
