import { useSelector } from "react-redux";
import props from "../../../redux/props";

const PositionsCTA = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <div className="-mt-10">
      <a
        href="#positions"
        className="text-center block w-fit mx-auto text-lg uppercase py-1 px-10 bg-lightGray text-gray"
      >
        {t.career.openPositions.headline}
      </a>
    </div>
  );
};

export default PositionsCTA;
