import { useCallback, useEffect, useState } from "react";
import entryList from "./entries/list";
import { BlogEntry } from "./entries/types";
import { Area } from "../useAreas/areas/types";

export enum SortingArg {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
}

type Response = [BlogEntry[] | undefined, SetFilter, SetSorting];

type SetFilter = (area: Area) => void;
type SetSorting = (sorting: SortingArg) => void;

const useBlog = (): Response => {
  const [entries, setEntries] = useState<BlogEntry[]>();
  const [areaFilter, setAreaFilter] = useState<Area>();
  const [sortingInternal, setSortingInternal] = useState<SortingArg>(
    SortingArg.DATE_DESC
  );

  const sort = useCallback(
    (entries: BlogEntry[]) => {
      const sortedEntries = entries.sort((a, b) => {
        if (sortingInternal === SortingArg.DATE_ASC) {
          return a.date.getTime() - b.date.getTime();
        } else {
          return b.date.getTime() - a.date.getTime();
        }
      });
      return sortedEntries;
    },
    [sortingInternal]
  );

  const updateEntries = useCallback(
    (filter: Area, sorting: SortingArg) => {
      let filteredEntries = entryList;
      if (areaFilter) {
        filteredEntries = filteredEntries.filter((e) => e.area === areaFilter);
      }
      setEntries(sort(filteredEntries));
      setAreaFilter(filter);
      setSortingInternal(sorting);
    },
    [areaFilter, sort]
  );

  useEffect(() => {
    if (!entries) {
      updateEntries(areaFilter, sortingInternal);
    }
  }, [entries, updateEntries, areaFilter, sortingInternal]);

  const setFilter: SetFilter = (area) => {
    updateEntries(area, sortingInternal);
  };

  const setSorting: SetSorting = (sorting) => {
    updateEntries(areaFilter, sorting);
  };

  return [entries, setFilter, setSorting];
};

export default useBlog;
