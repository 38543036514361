import { BlogEntry } from "./types";
import eughverbaendegegendsgvoverletzungen from "./eugh-verbaende-gegen-dsgvo-verletzungen";
import diezahlungsverzugskuendigunginderviruskrise from "./die-zahlungsverzugskuendigung-in-der-virus-krise";
import bussgeldkatalog2020 from "./bussgeldkatalog-2020";
import anspruchaufaktivitaetsprothesealszweitversorgung from "./anspruch-auf-aktivitaetsprothese-als-zweitversorgung";
import berlinermietendeckelinderviruskrise from "./berliner-mietendeckel-in-der-virus-krise";
import auskunftsfristdesberlinermietendeckels from "./auskunftsfrist-des-berliner-mietendeckels";
import dieraeumunginderviruskrise from "./die-raeumung-in-der-virus-krise";
import bussgelderinderviruskrise from "./bussgelder-in-der-virus-krise";
import diekuendigungdesmietverhaeltnissesinderviruskrise from "./die-kuendigung-des-mietverhaeltnisses-in-der-virus-krise";
import diemieteinderviruskrise from "./die-miete-in-der-virus-krise";
import mietpreisbremseinbrandenburgauchnacherneutemerlassunwirksam from "./mietpreisbremse-in-brandenburg-auch-nach-erneutem-erlass-unwirksam";
import mietpreisbremsejetztauchinbrandenburgunwirksam from "./mietpreisbremse-jetzt-auch-in-brandenburg-unwirksam";
import bghauchunternehmererhaltenbearbeitungsentgeltbeidarlehenzurueck from "./bgh-auch-unternehmer-erhalten-bearbeitungsentgelt-bei-darlehen-zurueck";
import neuesdatenschutzrechtseitmai2018jetzthandeln from "./neues-datenschutzrecht-seit-mai-2018-jetzt-handeln";
import bghebayauktioneneigengebotedesanbietersunwirksam from "./bgh-ebay-auktionen-eigengebote-des-anbieters-unwirksam";

const blogEntryList: BlogEntry[] = [
  eughverbaendegegendsgvoverletzungen,
  diezahlungsverzugskuendigunginderviruskrise,
  bussgeldkatalog2020,
  anspruchaufaktivitaetsprothesealszweitversorgung,
  berlinermietendeckelinderviruskrise,
  auskunftsfristdesberlinermietendeckels,
  dieraeumunginderviruskrise,
  bussgelderinderviruskrise,
  diekuendigungdesmietverhaeltnissesinderviruskrise,
  diemieteinderviruskrise,
  mietpreisbremseinbrandenburgauchnacherneutemerlassunwirksam,
  mietpreisbremsejetztauchinbrandenburgunwirksam,
  bghauchunternehmererhaltenbearbeitungsentgeltbeidarlehenzurueck,
  neuesdatenschutzrechtseitmai2018jetzthandeln,
  bghebayauktioneneigengebotedesanbietersunwirksam,
];

export default blogEntryList;
