import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Headline from "../../UI/Headline/Headline";
import TextWithHighlight from "../../UI/TextWithHighlight/TextWithHighlight";
import Container from "../../UI/Container/Container";

const Intro = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <div className="flex flex-col md:flex-row gap-10 md:gap-0 mt-14 md:mt-28">
        <div className="flex-1">
          <Headline
            prefix={t.home.intro.headlinePrefix}
            text={t.home.intro.headline}
            subline={t.home.intro.subline}
          />
        </div>
        <div className="flex-1">
          <TextWithHighlight
            prefix={t.home.intro.textPrefix}
            text={t.home.intro.text}
          />
        </div>
      </div>
    </Container>
  );
};

export default Intro;
