import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "bussgeldkatalog-2020",
  title: "Bußgeldkatalog 2020",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "felix-freist",
  date: new Date("2020-05-04"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Die Straßenverkehrs-Ordnung (kurz: StVO) ist mit Wirkung zum 28. April 2020 novelliert worden. Damit haben sich auch erhebliche Änderungen und Neuerungen des Bußgeldkataloges (BKatV) 2020 ergeben. Einige Beispiele:",
      ],
    },
    {
      type: ContentSectionType.ENUMERATION,
      content: [
        "Für Geschwindigkeitsüberschreitungen von 21 km/h innerorts und 26 km/h außerorts droht eine Geldbuße in Höhe von 80 Euro, 1 Punkt in Flensburg und 1 Monat Fahrverbot; bisher lag die Grenze für die Anordnung eine Fahrverbotes bei einer Geschwindigkeitsüberschreitung von 31 km/h bzw. 41 km/h;",
        "Die unerlaubte Nutzung einer Rettungsgasse wird jetzt genauso verfolgt und geahndet, wie das Nichtbilden einer Rettungsgasse. Es drohen Bußgelder zwischen 200 und 320 Euro, 2 Punkte in Flensburg, sowie 1 Monat Fahrverbot;",
        "Die vorschriftswidrige Nutzung von Gehwegen, linksseitig angelegten Radwegen und Seitenstreifen durch Fahrzeuge wird jetzt statt bis zu 25 Euro mit bis zu 100 Euro Geldbuße geahndet;",
        "Für das sogenannte Auto-Posing kann die Geldbuße für das Verursachen unnötigen Lärms und einer vermeidbaren Abgasbelästigung, sowie des unnützen Hin- und Herfahrens von bis zu 20 Euro auf bis zu 100 Euro angehoben werden.",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: [
        "Link zum bundeseinheitlichen Tatbestandskatalog: https://www.kba.de/DE/ZentraleRegister/FAER/BT_KAT_OWI/btkat_node.html",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: ["Rechtliche Fragen hierzu beantworten wir gern."],
    },
  ],
};

export default data;
