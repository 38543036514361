import { useNavigate } from "react-router-dom";
import Navigation from "./Navigation/Navigation";
import logo from "../../../../assets/image/logo/logo.svg";
import { AccountLink } from "@think-internet/zeus-frontend-package/auth/types";
import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import Container from "../../Container/Container";

type Props = {
  hideNavigation?: boolean;
  disableDynamicHeight?: boolean;
  small?: boolean;
};

const Header: React.FC<Props> = ({
  hideNavigation = false,
  disableDynamicHeight = false,
  small = false,
}) => {
  const navigate = useNavigate();
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK]);
  const isLoggedIn = () => !!accountLink;
  const t = useSelector((s) => s[props.TRANSLATION]);

  const goHome = () => {
    let path = "/";
    if (isLoggedIn()) {
      path = "/dashboard";
    }
    navigate(path);
  };

  return (
    <div className={`z-20  transition-all top-0 left-0 fixed w-full shadow-md`}>
      <Container>
        <div className="flex justify-between h-14 flex-row items-center">
          <div
            className={`absolute top-0 left-0 w-full h-full -z-10 bg-white`}
          ></div>
          <div onClick={goHome} className={`cursor-pointer`}>
            <img src={logo} alt={t.projectName} className={"h-10"} />
          </div>
          {!hideNavigation && <Navigation />}
        </div>
      </Container>
    </div>
  );
};

export default Header;
