import { AreaContent } from "./types";
import medizinrecht from "./medizinrecht";
import arbeitsrecht from "./arbeitsrecht";
import mietUndWohnungseigentumsrecht from "./mietUndWohnungseigentumsrecht";
import gewerblicherRechtschutzUndUrheberrecht from "./gewerblicherRechtschutzUndUrheberrecht";
import itUndDatenschutzrecht from "./itUndDatenschutzrecht";
import transportrecht from "./transportrecht";
import reiserecht from "./reiserecht";
import familienrecht from "./familienrecht";
import erbrecht from "./erbrecht";
import verkehrsrecht from "./verkehrsrecht";
import handelsUndGesellschaftsrecht from "./handelsUndGesellschaftsrecht";
import allgemeinesZivilrecht from "./allgemeinesZivilrecht";

const areaContentList: AreaContent[] = [
  medizinrecht,
  arbeitsrecht,
  mietUndWohnungseigentumsrecht,
  gewerblicherRechtschutzUndUrheberrecht,
  itUndDatenschutzrecht,
  transportrecht,
  reiserecht,
  familienrecht,
  erbrecht,
  verkehrsrecht,
  handelsUndGesellschaftsrecht,
  allgemeinesZivilrecht,
];

export default areaContentList;
