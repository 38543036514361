import { Area } from "../../useAreas/areas/types";

export enum ContentSectionType {
  HEADLINE = "HEADLINE",
  TEXT = "TEXT",
  ENUMERATION = "ENUMERATION",
}

type ContentSection = {
  type: ContentSectionType;
  content: string | string[];
};

export type BlogEntry = {
  id: string;
  title: string;
  area: Area;
  date: Date;
  lawyerID: string;
  imageURL: string;
  content: ContentSection[];
};
