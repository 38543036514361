import Layout from "../UI/Layout/Layout";
import Hero from "../UI/Hero/Hero";
import hero from "../../assets/image/home/hero/hero.webp";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Intro from "./Intro/Intro";
import Gallery from "./Gallery/Gallery";

const Kanzlei = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          subtitle={t.kanzlei.hero.subtitle}
          title={t.kanzlei.hero.title}
          fullWidthUnderline
          small
        />
        <Intro />
        <Gallery />
      </div>
    </Layout>
  );
};

export default Kanzlei;
