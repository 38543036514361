import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.REISERECHT,
  content: [
    "Bleibt Ihre gebuchte Urlaubsreise hinter Ihren Erwartungen zurück oder treten während der Reise erhebliche Mängel auf, die von einer Unterbringung in einer abweichenden Hotelkategorie, über Lärm- oder Geruchsbelästigung im Hotelzimmer bis zu Abweichung der Reiseroute auf Kreuzfahrten reichen können, über Ihre Ansprüche und Rechte in Bereichen",
    [
      "der Reisepreisminderung",
      "des Schadenersatzes",
      "der Kündigung und",
      "des Rücktritts vom Reisevertrag.",
    ],
    "Haben Sie mit der Reise eine Reiserücktrittsversicherung abgeschlossen, unterstützen wir Sie außerdem bei der Durchsetzung Ihrer Ansprüche, soweit die Versicherung die Erstattung von Stornokosten ganz oder teilweise ablehnt.",
    "Als Flugreisende unterstützen wir Sie bei der Durchsetzung von Ansprüchen nach der Verordnung (EG) 261/2004 sowie dem Montrealer Übereinkommen in Fällen von",
    [
      "Flugausfall,",
      "Annullierung",
      "erheblicher Verspätung eines Fluges",
      "Gepäckverlust oder Gepäckbeschädigung.",
    ],
  ],
  bullets: [
    "Entschädigung nach Verordnung (EG) 261/2004 bei Flugausfall, Annullierung und erheblicher Verspätung",
    "Ansprüche aus Montrealer Übereinkommen",
    "Reisevertragsrecht, insbesondere Rechte des Reisenden bei Reisemängeln",
  ],
  more: [],
};

export default data;
