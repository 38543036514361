import { Area } from "../../useAreas/areas/types";
import { Person } from "./types";

const data: Person = {
  id: "thorsten-mueller-winterhager",
  firstname: "Thorsten",
  lastname: "Müller-Winterhager",
  title: "Rechtsanwalt",
  description: "",
  areas: [Area.GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT],
  subAreas: [
    Area.GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT,
    Area.ITUNDDATENRECHT,
  ],
  phone: "030 / 75 79 64 0",
  mail: "thorsten.winterhager@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1969 in Bad Homburg",
    "Studium in Frankfurt/M. und Mainz",
    "Referendariat beim Brandenburgischen Oberlandesgericht",
    "Rechtsanwalt seit 1997, jetzt Berlin",
    "Fachanwalt für Gewerblichen Rechtsschutz",
  ],
  memberships: [
    "Arbeitsgemeinschaft Geistiges Eigentum und Medien (AGEM) im DAV",
  ],
};

export default data;
