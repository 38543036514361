type Props = {
  image: string;
  subtitle: string[];
  title: string;
  subline?: string;
  cta?: string;
  ctaLink?: string;
  small?: boolean;
  fullWidthUnderline?: boolean;
};

const Hero: React.FC<Props> = ({
  image,
  subtitle,
  title,
  subline,
  cta,
  ctaLink,
  small = false,
  fullWidthUnderline = false,
}) => {
  return (
    <div
      className={`${
        small ? "h-[75vh]" : "h-screen"
      } flex items-center justify-center bg-cover bg-center shadow-2xl`}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="flex items-center justify-center flex-col gap-5 md:gap-[25px]">
        <div>
          {subtitle.map((s, i) => (
            <div
              key={i}
              className="text-blue text-center text-lg uppercase leading-6"
            >
              {s}
            </div>
          ))}
        </div>
        <div className="relative md:mt-5">
          <div className="relative z-10 text-white text-3xl md:text-6xl leading-[4.75rem] font-bold text-center">
            {title}
          </div>
          <div
            className={`${
              fullWidthUnderline ? "w-full" : "w-[70%]"
            } left-1/2 -translate-x-1/2 mx-auto h-4 bg-blue opacity-80 shadow-md absolute top-[3.75rem] z-0`}
          ></div>
        </div>
        {!!subline && (
          <div className="text-xl md:text-3xl text-white text-center md:-mt-5">
            {subline}
          </div>
        )}
        {!!cta && !!ctaLink && (
          <a
            href={ctaLink}
            className="text-white text-sm font-bold pt-2 md:text-md uppercase bg-blue py-1 px-10 shadow-md mt-5"
          >
            {cta}
          </a>
        )}
      </div>
    </div>
  );
};

export default Hero;
