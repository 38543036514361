import { useState } from "react";
import Form from "../../UI/Form/Form";
import Input from "../../UI/Input/Input";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Currency from "../../UI/Input/Currency";
import DirectFileUpload, {
  OnCallbackFile,
  UPLOAD_TYPES,
} from "../../UI/DirectFileUpload/DirectFileUpload";
// import { FileForUpload } from "@think-internet/zeus-frontend-package/functionalInterface/types";
import { fileMaxFileSizeInBytes, fileMimeTypes } from "../../../utility";
import Button from "../../UI/Button/Button";

type Props = {
  title: string;
};

type Application = {
  title: string;
  firstname?: string;
  lastname?: string;
  mail?: string;
  phone?: string;
  enteringDate?: string;
  salaryExpectation?: number;
  fileKeys?: string[];
};

const ApplicationForm: React.FC<Props> = ({ title }) => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const [data, setData] = useState<Application>({
    title,
    fileKeys: [],
  });

  const submit = () => {
    alert("submit");
  };

  const set = (key: keyof Application) => (value: string) =>
    setData({ ...data, [key]: value });

  const onFile = (files: OnCallbackFile[]) => {
    setData({
      ...data,
      fileKeys: data.fileKeys.concat(files.map((f) => f.key)),
    });
  };

  return (
    <Form onSubmit={submit}>
      <div className="flex gap-5">
        <div className="flex flex-col gap-1 w-1/2">
          <Input
            required
            value={data.firstname}
            placeholder={t.generic.firstname}
            onChange={set("firstname")}
          />
          <Input
            required
            value={data.lastname}
            placeholder={t.generic.lastname}
            onChange={set("lastname")}
          />
          <Input
            required
            type="email"
            value={data.mail}
            placeholder={t.generic.mail}
            onChange={set("mail")}
          />
          <Input
            required
            value={data.phone}
            placeholder={t.generic.phone}
            onChange={set("phone")}
          />
          <Input
            required
            value={data.enteringDate}
            placeholder={t.career.openPositions.form.entryDate}
            onChange={set("enteringDate")}
          />
          <Currency
            required
            value={data.salaryExpectation}
            placeholder={t.career.openPositions.form.salaryExpectation}
            onChange={set("salaryExpectation")}
          />
        </div>
        <div className="flex flex-col gap-1 w-1/2">
          <DirectFileUpload
            uploadType={UPLOAD_TYPES.APPLICATION}
            allowedMimeTypes={fileMimeTypes}
            maxFileSizeInBytes={fileMaxFileSizeInBytes}
            onFilesCallback={onFile}
            multiple
          >
            <div>
              <div className="font-bold text-lg text-center text-gray">
                {t.career.openPositions.form.dragAndDropTitle}
              </div>
              <div>
                {t.career.openPositions.form.dragAndDropHint.map((h) => (
                  <div className="text-sm text-center">{h}</div>
                ))}
              </div>
            </div>
          </DirectFileUpload>
        </div>
      </div>
      <div className="mt-5 w-1/2 mx-auto">
        <Button
          text={t.career.openPositions.form.cta}
          type="submit"
          className="w-full uppercase"
        />
      </div>
    </Form>
  );
};

export default ApplicationForm;
