import hero from "../../../assets/image/areas/hero/image.png";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import props from "../../../redux/props";
import Layout from "../../UI/Layout/Layout";
import Hero from "../../UI/Hero/Hero";
import Container from "../../UI/Container/Container";
import useLawyers from "../../hooks/useLawyers/useLawyers";
import useAreas from "../../hooks/useAreas/useAreas";
import { Area } from "../../hooks/useAreas/areas/types";
import People from "../../Lawyers/Team/People/People";
import Headline from "../../UI/Headline/Headline";
import Overview from "../Overview/Overview";
import BlueGradientBG from "../../UI/BlueGradientBG/BlueGradientBG";

const SubjectAreaView = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const { areaid } = useParams();
  const people = useLawyers(null, [Area[areaid.toUpperCase()]]);
  const areaList = useAreas([areaid.toUpperCase() as Area]);

  if (!people || !areaList) return null;
  const area = areaList[0];
  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          small
          subtitle={t.subjectAreaView.hero.subtitle}
          title={t.areas[area.id]}
        />
        <Container>
          <div className="flex flex-col md:flex-row gap-20 my-14 md:my-28 relative">
            <div className="md:w-8/12">
              {area.content.map((c, i) => {
                if (Array.isArray(c)) {
                  return (
                    <ul className={`list-disc ml-5 ${i > 0 && "mt-3"}`}>
                      {c.map((li, i) => (
                        <li key={i}>{li}</li>
                      ))}
                    </ul>
                  );
                } else {
                  return <p className={`${i > 0 && "mt-3"}`}>{c}</p>;
                }
              })}
            </div>
            <div className="md:w-4/12">
              {area.bullets.length > 0 && (
                <>
                  <div className="font-bold text-lg">
                    {t.subjectAreaView.bullets.title}
                  </div>
                  <ul className="list-disc ml-5">
                    {area.bullets.map((b, i) => (
                      <li>{b}</li>
                    ))}
                  </ul>
                </>
              )}
              {area.more.length > 0 && (
                <>
                  <div className="font-bold text-lg mt-10">
                    {t.subjectAreaView.more.title}
                  </div>
                  <ul className="list-disc ml-5">
                    {area.more.map((b, i) => (
                      <li>
                        <a
                          href={b.href}
                          target="_blank"
                          rel="noreferrer"
                          className="hover:underline"
                        >
                          {b.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </Container>
        {people.length > 0 && (
          <div className="py-20 bg-lightGray">
            <Container>
              <Headline
                prefix={t.subjectAreaView.lawyersList.headlinePrefix}
                text={t.subjectAreaView.lawyersList.headline}
                subline={t.subjectAreaView.lawyersList.subline}
              />
              <div className="mt-5">
                <People limit={0} slider={true} people={people} />
              </div>
            </Container>
          </div>
        )}
        <BlueGradientBG>
          <div className="py-20">
            <Container>
              <Headline
                prefix={t.subjectAreaView.areaOverview.headlinePrefix}
                text={t.subjectAreaView.areaOverview.headline}
                subline={t.subjectAreaView.areaOverview.subline}
                invert
              />
              <div className="mt-5">
                <Overview slider />
              </div>
            </Container>
          </div>
        </BlueGradientBG>
      </div>
    </Layout>
  );
};

export default SubjectAreaView;
