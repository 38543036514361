import hero from "../../../assets/image/lawyers/view/hero.png";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import props from "../../../redux/props";
import Layout from "../../UI/Layout/Layout";
import Hero from "../../UI/Hero/Hero";
import AreasSlider from "../AreasSlider/AreasSlider";
import Container from "../../UI/Container/Container";
import styled from "styled-components";
import Headline from "../../UI/Headline/Headline";
import Team from "../Team/Team";
import useLawyers from "../../hooks/useLawyers/useLawyers";

const Lawyers = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const { id } = useParams();
  const people = useLawyers([id]);

  const Section = styled.div`
    width: calc(50% - 2.5rem / 2);
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 100%;
    }
  `;

  if (!people) return null;
  const { person, image } = people[0];
  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          small
          subtitle={t.lawyersView.hero.subtitle}
          title={`${person.firstname} ${person.lastname}`}
        />
        <Container>
          <div className="flex mt-14 md:mt-28 flex-wrap gap-10 md:gap-y-20 relative">
            <div className="md:absolute w-56 md:-translate-y-full right-0 mx-auto">
              <img
                src={image}
                alt=""
                className="w-full aspect-square object-cover"
              />
            </div>
            <Section>
              <Headline
                prefix={person.firstname}
                text={person.lastname}
                subline={`${t.lawyers.areaPrefix}${person.areas.map((a, i) =>
                  i > 0 ? ` & ${t.areas[a]}` : t.areas[a]
                )}`}
              />
            </Section>
            <Section>
              <div className="font-bold text-lg">{person.title}</div>
              <a href={`mailto:${person.mail}`} className="text-light block">
                {person.mail}
              </a>
              <a href={`tel:${person.phone}`} className="text-light block">
                {person.phone}
              </a>
              <a
                className="text-light block"
                href={t.lawyersView.contact.form.link}
              >
                {t.lawyersView.contact.form.label}
              </a>
              <div className="text-light">{person.location}</div>
            </Section>
            <Section>
              <div className="font-bold text-lg">
                {t.lawyersView.vita.title}
              </div>
              <ul className="list-disc ml-5">
                {person.vita.map((v, i) => (
                  <li key={i} className="text-light">
                    {v}
                  </li>
                ))}
              </ul>
            </Section>
            <Section>
              {person.memberships.length > 0 && (
                <>
                  <div className="font-bold text-lg">
                    {t.lawyersView.memberships.title}
                  </div>
                  <ul className="list-disc ml-5">
                    {person.memberships.map((v, i) => (
                      <li key={i} className="text-light">
                        {v}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Section>
            <div>
              <Headline
                prefix={t.lawyersView.areas.prefix}
                text={t.lawyersView.areas.text}
                subline={t.lawyersView.areas.subline}
              />
            </div>
          </div>
          <div className="mt-5">
            <AreasSlider areas={person.subAreas} />
          </div>
        </Container>
        <Team slider />
      </div>
    </Layout>
  );
};

export default Lawyers;
