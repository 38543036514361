import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "thomas-abel",
  firstname: "Thomas",
  lastname: "Abel",
  title: "Rechtsanwalt",
  description: "",
  areas: [Area.ALLGEMEINESZIVILRECHT],
  subAreas: [Area.ALLGEMEINESZIVILRECHT],
  phone: "030 / 75 79 64 0",
  mail: "thomas.abel@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1968 in Marburg",
    "Studium in Marburg und Berlin",
    "Referendariat beim Kammergericht Berlin",
    "1997 Justitiar Amts- und Stadtverwaltung Kyritz",
    "1998-2005 Deutscher Städte- und Gemeindebund",
    "Rechtsanwalt seit 2005 in Berlin",
    "Seit 2005 Geschäftsführer im Verband kommunaler Unternehmen e.V.",
    "Rechtsanwalt Abel ist aufgrund seiner Verbandstätigkeit derzeit nicht aktiv in der Kanzlei tätig.",
  ],
  memberships: [],
};

export default data;
