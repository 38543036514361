import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import verkehrsrechtIcon from "../../../../assets/image/areas/icons/default/Verkehrsrecht.svg";
import reiserechtIcon from "../../../../assets/image/areas/icons/default/Reiserecht.svg";

import medizinrechtIcon from "../../../../assets/image/areas/icons/default/Medizinrecht.svg";
import arbeitsrechtIcon from "../../../../assets/image/areas/icons/default/Arbeitsrecht.svg";
import familienrechtIcon from "../../../../assets/image/areas/icons/default/Familienrecht.svg";
import itUndDatenrechtIcon from "../../../../assets/image/areas/icons/default/ItUndDatenrecht.svg";
import mietUndWohnungseigentumsrechtIcon from "../../../../assets/image/areas/icons/default/MietUndWohnungseigentumsrecht.svg";
import gewerblicherRechtsschutzUndUrheberrechtIcon from "../../../../assets/image/areas/icons/default/GewerblicherRechtsschutzUndUrheberrecht.svg";
import erbrechtIcon from "../../../../assets/image/areas/icons/default/Erbrecht.svg";
import transportrechtIcon from "../../../../assets/image/areas/icons/default/Transportrecht.svg";
import handelUndGesellschaftsrechtIcon from "../../../../assets/image/areas/icons/default/HandelUndGesellschaftsrecht.svg";
import allgemeinesZivilrechtIcon from "../../../../assets/image/areas/icons/default/AllgemeinesZivilrecht.svg";

import verkehrsrechtHoverIcon from "../../../../assets/image/areas/icons/hover/Verkehrsrecht.svg";
import reiserechtHoverIcon from "../../../../assets/image/areas/icons/hover/Reiserecht.svg";

import medizinrechtHoverIcon from "../../../../assets/image/areas/icons/hover/Medizinrecht.svg";
import arbeitsrechtHoverIcon from "../../../../assets/image/areas/icons/hover/Arbeitsrecht.svg";
import familienrechtHoverIcon from "../../../../assets/image/areas/icons/hover/Familienrecht.svg";
import itUndDatenrechtHoverIcon from "../../../../assets/image/areas/icons/hover/ItUndDatenrecht.svg";
import mietUndWohnungseigentumsrechtHoverIcon from "../../../../assets/image/areas/icons/hover/MietUndWohnungseigentumsrecht.svg";
import gewerblicherRechtsschutzUndUrheberrechtHoverIcon from "../../../../assets/image/areas/icons/hover/GewerblicherRechtsschutzUndUrheberrecht.svg";
import erbrechtHoverIcon from "../../../../assets/image/areas/icons/hover/Erbrecht.svg";
import transportrechtHoverIcon from "../../../../assets/image/areas/icons/hover/Transportrecht.svg";
import handelUndGesellschaftsrechtHoverIcon from "../../../../assets/image/areas/icons/hover/HandelUndGesellschaftsrecht.svg";
import allgemeinesZivilrechtHoverIcon from "../../../../assets/image/areas/icons/hover/AllgemeinesZivilrecht.svg";

const icons = {
  VERTRAGSRECHT: verkehrsrechtIcon,
  VERKEHRSRECHT: verkehrsrechtIcon,
  REISERECHT: reiserechtIcon,
  MEDIZINRECHT: medizinrechtIcon,
  ARBEITSRECHT: arbeitsrechtIcon,
  FAMILIENRECHT: familienrechtIcon,
  ITUNDDATENRECHT: itUndDatenrechtIcon,
  MIETUNDWOHNUNGSEIGENTUMSRECHT: mietUndWohnungseigentumsrechtIcon,
  GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT:
    gewerblicherRechtsschutzUndUrheberrechtIcon,
  ERBRECHT: erbrechtIcon,
  TRANSPORTRECHT: transportrechtIcon,
  HANDELUNDGESELLSCHAFTSRECHT: handelUndGesellschaftsrechtIcon,
  ALLGEMEINESZIVILRECHT: allgemeinesZivilrechtIcon,
};

const hoverIcons = {
  VERTRAGSRECHT: verkehrsrechtHoverIcon,
  VERKEHRSRECHT: verkehrsrechtHoverIcon,
  REISERECHT: reiserechtHoverIcon,
  MEDIZINRECHT: medizinrechtHoverIcon,
  ARBEITSRECHT: arbeitsrechtHoverIcon,
  FAMILIENRECHT: familienrechtHoverIcon,
  ITUNDDATENRECHT: itUndDatenrechtHoverIcon,
  MIETUNDWOHNUNGSEIGENTUMSRECHT: mietUndWohnungseigentumsrechtHoverIcon,
  GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT:
    gewerblicherRechtsschutzUndUrheberrechtHoverIcon,
  ERBRECHT: erbrechtHoverIcon,
  TRANSPORTRECHT: transportrechtHoverIcon,
  HANDELUNDGESELLSCHAFTSRECHT: handelUndGesellschaftsrechtHoverIcon,
  ALLGEMEINESZIVILRECHT: allgemeinesZivilrechtHoverIcon,
};

const Item: React.FC<{ area: string }> = ({ area }) => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <a href={`/fachgebiete/${area}`}>
      <div className="flex flex-col group justify-center items-center bg-lightGray hover:bg-blue aspect-square">
        <div className="aspect-square w-14 h-14">
          <img
            src={icons[area]}
            alt={area}
            className="max-w-full max-h-full group-hover:hidden"
          />
          <img
            src={hoverIcons[area]}
            alt={area}
            className="max-w-full max-h-full !hidden group-hover:!block"
          />
        </div>
        <div className="text-center text-blue group-hover:text-lightGray font-light text-xs md:text-sm mt-2 uppercase">
          {t.areasSlider.itemLabel}
        </div>
        <div className="text-center w-full px-3.5 text-sm md:text-md text-blue group-hover:text-white mt-2 font-bold truncate md:overflow-visible md:whitespace-normal">
          {t.areas[area]}
        </div>
      </div>
    </a>
  );
};

export default Item;
