import Hero from "../UI/Hero/Hero";
import Layout from "../UI/Layout/Layout";
import Boxes from "./Boxes/Boxes";
import PositionsCTA from "./PositionsCTA/PositionsCTA";
import Reasons from "./Reasons/Reasons";
import Quote from "./Quote/Quote";
import Lawyers from "./Lawyers/Lawyers";
import Application from "./Application/Application";
import hero from "../../assets/image/career/hero/hero.webp";
import { useSelector } from "react-redux";
import props from "../../redux/props";

const Home = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authLevelRequired={false}>
      <div className="pb-32">
        <Hero
          image={hero}
          subtitle={t.career.hero.subtitle}
          title={t.career.hero.title}
          subline={t.career.hero.subline}
        />
        <Boxes />
        <PositionsCTA />
        <Reasons />
        <Quote />
        <Lawyers />
        <Application />
      </div>
    </Layout>
  );
};

export default Home;
