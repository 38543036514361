import styled from "styled-components";

const Gradient = styled.div`
  background: rgb(70, 133, 197);
  background: linear-gradient(
    45deg,
    rgba(70, 133, 197, 1) 0%,
    rgba(13, 40, 69, 1) 100%
  );
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

const BlueGradientBG: React.FC<Props> = ({ children, className = "" }) => {
  return <Gradient className={className}>{children}</Gradient>;
};

export default BlueGradientBG;
