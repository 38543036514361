import diedrichwilms from "../../../assets/image/lawyers/diedrich.jpg";
import friedrichschulze from "../../../assets/image/lawyers/friedrich.jpg";
import thorstenmuellerwinterhager from "../../../assets/image/lawyers/thorsten.jpg";
import felixfreist from "../../../assets/image/lawyers/felix.jpg";
import kathrinschwartz from "../../../assets/image/lawyers/kathrin.jpg";
import robertschad from "../../../assets/image/lawyers/robert.jpg";
import bettinaotto from "../../../assets/image/lawyers/bettina.jpg";
import thomasabel from "../../../assets/image/lawyers/thomas.jpg";
import { useEffect, useState } from "react";
import { Person } from "./lawyers/types";
import people from "./lawyers/list";
import { Area } from "../useAreas/areas/types";

const useLawyers = (idList?: string[], areaList?: Area[]) => {
  const [lawyers, setLawyers] = useState<{ image: string; person: Person }[]>();

  useEffect(() => {
    const images = [
      diedrichwilms,
      friedrichschulze,
      thorstenmuellerwinterhager,
      felixfreist,
      kathrinschwartz,
      robertschad,
      bettinaotto,
      thomasabel,
    ];

    if (!lawyers) {
      if (idList) {
        const filteredPeople = people.reduce((acc, person, i) => {
          if (idList.includes(person.id)) {
            acc.push({ person, image: images[i] });
          }
          return acc;
        }, []);
        setLawyers(filteredPeople);
      } else if (areaList) {
        const filteredPeople = people.reduce((acc, person, i) => {
          if (
            areaList.some(
              (a) => person.areas.includes(a) || person.subAreas.includes(a)
            )
          ) {
            acc.push({ person, image: images[i] });
          }
          return acc;
        }, []);
        setLawyers(filteredPeople);
      } else {
        setLawyers(people.map((p, i) => ({ image: images[i], person: p })));
      }
    }
  }, [lawyers, idList, areaList]);

  return lawyers;
};

export default useLawyers;
