import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.MIETUNDWOHNUNGSEIGENTUMSRECHT,
  content: [
    "Wohnraummietrecht:",
    "Das Wohnraummietrecht gehört zu der existentiellen Lebensgestaltung und wirtschaftlichen Existenz der Mieter und spielt daher im Alltag stets eine große und wiederkehrende Rolle.",
    "Wir bieten Ihnen als Mieter daher vor allem folgende Leistungen an:",
    [
      "Prüfung von Mietverträgen, insb. Schönheitsreparaturklauseln, Staffelmiete, Indexmiete und Zeitmietverträge",
      "Beratung bei einer ordentlichen oder fristlosen Kündigung, insbesondere bei Zahlungsverzug und Eigenbedarf",
      "Abwehr von Räumungsklagen und Beratung bei Räumungsvergleichen und Räumungsschutz",
      "Prüfung von Mietmängeln und Unterstützung bei der Mängelanzeige und Mietminderung",
      "Prüfung von Betriebs- und Heizkostenabrechnungen",
      "Beratung bei Instandsetzungs- und Instandhaltungsmaßnahmen sowie bei Modernisierungsankündigungen",
      "Prüfung von Mieterhöhungsverlangen",
      "Beratung bei Beendigung des Mietverhältnisses, insb. Kautionsrückzahlung und Abstandszahlung bei einem Aufhebungsvertrag",
    ],
    "Gewerbemietrecht und Pachtrecht:",
    "Die Vermietung oder Verpachtung stellt sowohl für den Vermieter als auch für den gewerbetreibenden Mieter meist deren wirtschaftliche Existenz dar und ist daher auch von großer persönlicher Bedeutung.",
    "Wir bieten Ihnen als Vermieter oder Mieter von Gewerbeeinheiten u.a. folgende Leistungen an:",
    [
      "Beratung und Gestaltung von Gewerbemietverträgen und Pachtverträgen, insb. Vertragslaufzeit, Verlängerungsklauseln, Optionsklauseln, Konkurrenzschutz, Schriftformerfordernis, Minderungs- und Aufrechnungsverbot, Ein- und Umbauten und deren Rückbauverpflichtung",
      "Beratung während des Mietverhältnisses, insb. hinsichtlich Kündigungen, Abmahnungen, Zahlungsansprüche",
    ],
    "Wohnungseigentumsrecht:",
    "Der Kauf einer Immobilie zur Eigennutzung oder Kapitalanlage bedarf zuvor einer gründlichen Prüfung insb. hinsichtlich der Instandhaltung der Immobilie (Instandhaltungsstau), Eigentümerbeschlüsse, Eigentums- und Nutzungsverhältnisse.",
    "Wir unterstützen Sie daher nicht nur beim Kauf einer bereits errichteten Immobilie sondern auch bei Bauträgerobjekten/ Neubauten und begleiten Sie von der Eigentumseintragung bis zur Wohnungseigentümerversammlung.",
    "Wir bieten Wohnungseigentümern oder Wohnungseigentümergemeinschaften vor allem folgende Leistungen an:",
    [
      "Beratung bei Kauf oder Verkauf einer Eigentumsimmobilie, auch hinsichtlich der Abgrenzung von Gemeinschafts- und Sondereigentum und der Einräumung von Sondernutzungsrechten",
      "Überprüfung der Teilungserklärung",
      "Unterstützung bei der Wohnungseigentümerversammlung; Vorbereitung und/ oder Prüfung von Beschlüssen der WEG",
      "Gerichtliche Vertretung in Beschlussanfechtungsverfahren der WEG, einzelner Eigentümer und/ oder des Verwalters",
    ],
  ],
  bullets: [
    "Vertragsgestaltung",
    "Wohnraummiete und",
    "gewerbliches Mietrecht",
    "Wohnungseigentum",
  ],
  more: [],
};

export default data;
