import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Container from "../../UI/Container/Container";
import styled from "styled-components";
import kathrin from "../../../assets/image/lawyers/kathrin.jpg";

const Item = styled.div`
  width: calc(100% / 3 - 100px * 2 / 3);
`;

const Lawyers = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  const images = [kathrin, kathrin, kathrin, kathrin];

  return (
    <Container>
      <div className="mt-24 text-center text-2xl text-blue">
        {t.lawyers.headline}
      </div>
      <div className="flex flex-wrap gap-y-20 gap-x-[100px] mt-16">
        {t.lawyers.items.map((item, i) => (
          <Item>
            <div
              className="bg-center bg-cover w-40 aspect-square rounded-full mx-auto"
              style={{ backgroundImage: `url(${images[i]})` }}
            ></div>
            <div className="text-center text-lg text-blue mt-2">
              {item.name}
            </div>
            <div className="text-center text-sm text-gray">{item.title}</div>
            <div className="text-center text-sm text-gray mt-3">
              {item.description}
            </div>
            <div className="mt-4">
              {item.areas.map((a) => (
                <div className="flex gap-2 items-center">
                  <div className="w-4 aspect-square rounded-full bg-blue"></div>
                  <div className="text-gray">{a}</div>
                </div>
              ))}
            </div>
          </Item>
        ))}
      </div>
    </Container>
  );
};

export default Lawyers;
