import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.ITUNDDATENRECHT,
  content: [
    "IT-Recht",
    "Wir beraten Sie beim Abschluss von Verträgen über den Erwerb oder die Lizensierung von Software und bei Verträgen über die Beauftragung von IT-Projekten.",

    "Datenschutzrecht",
    "Der Schutz personenbezogener Daten Ihrer Mitarbeiter und Kunden ist wichtig. Neben technisch- organisatorischen Maßnahmen zum Schutz der Daten sind auch rechtliche Vorgaben zu beachten.",
    "Wir beraten und unterstützen Sie bei der Erstellung und Anpassung von",

    "Datenschutzerklärung.",
    "Shopbetreiber und andere gewerbliche Anbieter im Internet sind verpflichtet, die Besucher ihrer Internetseite darüber aufzuklären, ob und welche personenbezogenen Daten beim Besuch der Internetseite und der Nutzung der dort angebotenen Services erfasst werden, und was mit diesen Daten geschieht. Im Rahmen einer Datenschutzerklärung soll jeder Besucher über die Nutzung seiner Daten und die ihm zustehenden Informations- und sonstigen Rechte unterrichtet werden..",
    "Wir beraten und unterstützen Sie bei der Erstellung einer gesetzeskonformen Datenschutzerklärung.",

    "Externer Datenschutzbeauftragter",
    "Das BDSG sieht für Unternehmen, in denen personenbezogene Daten verarbeitet werden, zwingend die Bestellung eines Betrieblichen Datenschutzbeauftragten vor. Dieser muss die hierfür erforderliche Sachkunde aufweisen. Wir übernehmen für Sie die Aufgaben des Betrieblichen Datenschutzbeauftragten (BDSB) und schulen Ihre Mitarbeiter in Grundlagen des Datenschutzrechts.",

    "Internetrecht",
    "Online Händler/ ebay Händler/Amazon Händler",
    "Die gesetzlichen Vorgaben, die ein Online-Händler zu beachten hat, sind komplex und unübersichtlich. Immer wieder ändern sich diese oder kommen neue Regelungen hinzu. Bei Nichtbeachtung drohen Abmahnungen von Wettbewerbern oder Verbänden. Das kann schnell teuer werden. Wir beraten Sie, damit Sie rechtssicher über das Internet verkaufen können, insbesondere in folgenden Tätigkeitsfeldern:",

    [
      "rechtlichen Prüfung/ Neuerstellung Ihrer Online – AGB",
      "rechtswirksame Einbindung",
      "Widerrufsrecht",
      "Pflichtangaben",
      "rechtliche Prüfung Ihres Online Shops (z.B. Preisangaben, Pflichtangaben)",
      "rechtssichere Gestaltung Ihrer Internetseite",
      "Impressum",
      "Disclaimer",
      "Verlinkung auf fremde Internetseiten",
      "Urheberrecht: Übernahme fremden Contents (Bilder, Videos, Texte)",
      "Newsletterversand",
      "Domainrecht",
      "Keyword Advertising (z.B. Google AdWords)",
    ],

    "Abmahnung und Unterlassungserklärung",
    "Haben Sie eine Abmahnung erhalten, prüfen wir die Abmahnung auf ihre Begründetheit und führen die weitere Korrespondenz mit den Abmahnanwälten.",

    "Social Media (Facebook & Co)",
    "In den Sozialen Medien werden täglich Millionen von Inhalten geteilt und geliked.",

    [
      "Pflichtangaben",
      "Haftungsfragen",
      "Rechte bei rechtsverletzenden Äußerungen bei Facebook",
      "Verwendung des Like - Buttons",
    ],
    "Beratung von Verbrauchern",
    "Der Gesetzgeber hat zum Schutz von Verbrauchern zahlreiche Regelungen vorgesehen. Nicht immer jedoch ist es einfach, diese Rechte durchzusetzen. Wir klären Sie über Ihre Rechte auf und helfen Ihnen bei deren Durchsetzung.",

    "Filesharing",
    "Das Tauschen von Film- und Musikdateien kann schnell teuer werden. Abmahnanwälte verlangen Honorar und Schadensersatz, die sich schnell auf einen vierstelligen Betrag addieren können. Wir unterstützen Sie nach Erhalt einer Abmahnung, prüfen die Abmahnung auf ihre Begründetheit und führen die weitere Korrespondenz mit den Abmahnanwälten.",
  ],
  bullets: [
    "Allgemeine Geschäftsbedingungen",
    "Auftragsdatenverarbeitung",
    "Betriebsvereinbarungen zum Datenschutz",
    "Datenschutzerklärung",
    "Domains",
    "Externen Datenschutzbeauftragter",
    "File-Sharing",
    "Internetrecht",
    "Lizenzverträge",
    "rechtliche Beratung von Online – Händlern",
    "Schulung im Datenschutz",
    "Social Media",
  ],
  more: [],
};

export default data;
