import styled from "styled-components";
import Item from "../../SubjectAreas/Overview/Item/Item";
import Slider from "../../UI/Slider/Slider";
import { Area } from "../../hooks/useAreas/areas/types";

type Props = {
  areas?: string[];
};

const ItemWrapper = styled.div`
  padding: 0 1rem;
`;

const AreasSlider: React.FC<Props> = ({ areas }) => {
  const getItems = () => {
    if (Array.isArray(areas)) {
      return areas.map((area) => (
        <ItemWrapper>
          <Item area={area} />
        </ItemWrapper>
      ));
    } else {
      return Object.keys(Area).map((area) => (
        <ItemWrapper>
          <Item area={area} />
        </ItemWrapper>
      ));
    }
  };

  return <Slider items={getItems()} />;
};

export default AreasSlider;
