import { useSelector } from "react-redux";
import bg from "../../../assets/image/contact/quoteBG.png";
import props from "../../../redux/props";

const Quote = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <div
      className="bg-cover bg-center py-28"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="w-10/12 md:w-1/2 mx-auto">
        <div className="text-lg md:text-xl text-white text-center">
          {t.contact.quote}
        </div>
      </div>
    </div>
  );
};

export default Quote;
