import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.VERKEHRSRECHT,
  content: [
    "Wir unterstützen Sie in der Abwehr und Geltendmachung im Verkehrs- und Ordnungswidrigkeitenrecht, insbesondere bei",
    [
      "der Verteidigung im Verkehrsstraf- und Ordnungswidrigkeitenverfahren (Bußgeldverfahren), u.a. bei eines Rotlichtverstoßes, Geschwindigkeitsüberschreitung, Trunkenheit im Straßenverkehr",
      "der Vermeidung eines Fahrverbotes",
      "der Wiedererlangung der Fahrerlaubnis, MPU",
      "der Schadensregulierung, u.a. bei der Geltendmachung von materiellen Schäden und Schmerzensgeldansprüchen (Nutzungsausfall und Haushaltsführung) nach einem Verkehrsunfall gegenüber dem Unfallgegner und gegenüber der eigenen Fahrzeugversicherung",
      "der rechtlichen Beratung für einen Kauf und/ oder Verkauf eines Fahrzeuges oder eines Fahrzeugleasings",
    ],
  ],
  bullets: [
    "Verkehrsunfallabwicklung",
    "Ordnungswidrigkeiten und Straftaten",
    "Versicherungsrecht",
    "Verträge rund um das KFZ",
  ],
  more: [],
};

export default data;
