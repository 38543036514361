import { useSelector } from "react-redux";
import {
  BlogEntry,
  ContentSectionType,
} from "../../../hooks/useBlog/entries/types";
import props from "../../../../redux/props";

type Props = {
  entry: BlogEntry;
};

const Card: React.FC<Props> = ({ entry }) => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  const getFirstText = () => {
    for (const section of entry.content) {
      if (section.type === ContentSectionType.TEXT) {
        if (Array.isArray(section.content)) {
          return section.content[0].slice(0, 100) + "...";
        }
        return section.content.slice(0, 100) + "...";
      }
    }
    return;
  };

  return (
    <a className="flex flex-col gap-2" href={`/blog/${entry.id}`}>
      <img
        src={entry.imageURL}
        alt={entry.title}
        className="aspect-[4/3] object-cover object-center"
      />
      <div className="uppercase text-lightblue truncate">
        {t.areas[entry.area]}
      </div>
      <div className="truncate font-bold">{entry.title}</div>
      <div className="break-words">{getFirstText()}</div>
    </a>
  );
};

export default Card;
