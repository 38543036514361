import Layout from "../UI/Layout/Layout";
import Hero from "../UI/Hero/Hero";
import hero from "../../assets/image/home/hero/hero.webp";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Intro from "./Intro/Intro";
import Team from "./Team/Team";

const Lawyers = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authLevelRequired={false}>
      <div>
        <Hero
          image={hero}
          small
          subtitle={t.lawyers.hero.subtitle}
          title={t.lawyers.hero.title}
        />
        <Intro />
        <Team />
      </div>
    </Layout>
  );
};

export default Lawyers;
