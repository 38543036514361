import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "die-raeumung-in-der-virus-krise",
  title: "Die Räumung in der Virus-Krise",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "kathrin-schwartz",
  date: new Date("2020-04-04"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Räumungsfristen sind wegen der Corona-Krise mindestens bis zum 30. Juni 2020 zu verlängern.",
        "Das Landgericht Berlin hat entschieden, dass Räumungsfristen aus einem Räumungsurteil „derzeit grundsätzlich bis zum 30. Juni 2020 zu erstrecken oder entsprechend zu verlängern“ sind.",
        "Ein zur Räumung seiner Wohnung verurteilter Mieter hatte vor der Berufungskammer eine Verlängerung seiner für Ende März angesetzten Räumungsfrist auf Juni 2020 verlangt.",
        "Die 67. Zivilkammer des Landgerichts Berlin (Az. 67 S 16/20) gewährte dies und urteilte aus, dass aufgrund der momentan geltenden Eindämmungsmaßnahmen gegen das Coronavirus die Beschaffung von Ersatzwohnraum für den Mieter „überwiegend unwahrscheinlich, wenn nicht sogar ausgeschlossen“ ist.",
      ],
    },
  ],
};

export default data;
