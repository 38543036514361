import { useDropzone } from "react-dropzone";
import useToast from "../../hooks/useToast";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { isNumber } from "lodash";

type Props = {
  onFiles: (files: File[]) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  allowedMimeTypes?: string[];
  maxFileSizeInBytes?: number;
  multiple?: boolean;
};

const DragAndDrop: React.FC<Props> = ({
  onFiles,
  children,
  className = "",
  disabled = false,
  allowedMimeTypes = null,
  maxFileSizeInBytes,
  multiple = false,
}) => {
  const toast = useToast();
  const t = useSelector((s) => s[props.TRANSLATION]);

  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      if (isNumber(maxFileSizeInBytes)) {
        const reachedMaxFileSize = files.some(
          (file) => file.size > maxFileSizeInBytes
        );
        if (!reachedMaxFileSize) {
          onFiles(files);
        } else {
          toast(t.UI.dnd.error.size);
        }
      } else {
        onFiles(files);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multiple,
    maxSize: maxFileSizeInBytes,
    accept: allowedMimeTypes,
  });

  return (
    <div
      className={`${
        disabled && "opacity-30 cursor-not-allowed"
      } outline-none h-full w-full ${className}`}
      {...(disabled ? {} : getRootProps())}
    >
      {!disabled && <input {...getInputProps()} />}

      {children}
    </div>
  );
};

export default DragAndDrop;
