import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "die-kuendigung-des-mietverhaeltnisses-in-der-virus-krise",
  title: "Die Kündigung des Mietverhältnisses in der Virus-Krise",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "felix-freist",
  date: new Date("2020-03-27"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Über die fortbestehende Pflicht zur Mietzahlung in der Virus-Krise hatten wir bereits am 23. März 2020 berichtet; s. unten. ",
        "Heute, am 27. März 2020, hat der Bundesrat dem Gesetzesentwurf zugestimmt, durch den die Kündigungsmöglichkeit des Vermieters vorübergehend eingeschränkt wird. ",
        "Kurz gefasst, gilt danach folgendes:",
      ],
    },
    {
      type: ContentSectionType.ENUMERATION,
      content: [
        "das Kündigungsrecht des Vermieters aufgrund von Mietrückständen ist für die Dauer von 24 Monaten ausgeschlossen;",
        "dies gilt nur für Mietrückstände, die in der Zeit vom 01.04.2020 bis zum 30.06.2020 aufgrund der Corona-Pandemie entstehen;",
        "die Ursächlichkeit zwischen der nicht bzw. nicht vollständigen Mietzahlung und der Corona-Pandemie ist im Streitfall durch den Mieter glaubhaft zu machen;",
        "gleicht der Mieter den Mietrückstand nicht bis zum 30.06.2022 aus, so ist der Vermieter wieder zur Kündigung berechtig;",
        "andere Kündigungsrechte bleiben hiervon unberührt;",
        "die Pflicht zur fristgerechten Mietzahlung bleibt bestehen und der Vermieter bleibt zur Durchsetzung seiner Zahlungsansprüche berechtigt.",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: "Rechtliche Fragen hierzu beantworten wir gern.",
    },
  ],
};

export default data;
