import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Headline from "../../UI/Headline/Headline";
import TextWithHighlight from "../../UI/TextWithHighlight/TextWithHighlight";
import Container from "../../UI/Container/Container";

const Intro = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <div className="flex flex-col md:flex-row gap-10 md:gap-0 pt-14 md:pt-28">
        <div className="flex-1 z-10">
          <Headline
            prefix={t.blog.intro.headlinePrefix}
            text={t.blog.intro.headline}
            subline={t.blog.intro.subline}
          />
        </div>
        <div className="flex-1 z-10">
          <TextWithHighlight text={t.blog.intro.text} />
        </div>
      </div>
    </Container>
  );
};

export default Intro;
