import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "bettina-otto",
  firstname: "Dr. Bettina",
  lastname: "Otto",
  title: "Rechtsanwältin",
  description: "",
  areas: [Area.FAMILIENRECHT],
  subAreas: [Area.FAMILIENRECHT],
  phone: "030 / 75 79 64 0",
  mail: "bettina.otto@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [
    "Geboren 1977 in Berlin",
    "Studium in Berlin und Madrid",
    "Referendariat beim Kammergericht Berlin",
    "Rechtsanwältin seit 2006 in Berlin",
    "Promotion 2011",
  ],
  memberships: [],
};

export default data;
