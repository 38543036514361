import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "neues-datenschutzrecht-seit-mai-2018-jetzt-handeln",
  title: "Neues Datenschutzrecht seit Mai 2018 – jetzt handeln!",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "thorsten-mueller-winterhager",
  date: new Date("2017-10-04"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        'Am 25. Mai 2018 trat mit der Europäischen Datenschutzgrundverordnung (DSGVO) und dem entsprechend "europäisierten" Bundesdatenschutzgesetz ein neues Datenschutzrecht in Kraft. Neben zahlreichen Regelungen, die uns bereits bekannt sind, finden sich im Gesetz nun deutlich umfangreichere Prüfungs- und Dokumentationspflichten als früher. Es besteht weiterhin die Pflicht zur Bestellung eines Betrieblichen Datenschutzbeauftragten für Unternehmen, bei denen regelmäßig mehr als 20 Mitarbeiter Zugang zu personenbezogenen Daten haben. Neu eingeführt wurden Schmerzensgeldansprüche Betroffener. Zudem können Verstöße nun mit drastisch erhöhten Bußgeldern von bis zu 20 Mio. EUR geahndet werden.',
        "Wir beraten Sie bei der Erstellung eines Datenschutzkonzepts sowie der für die Erfüllung der neuen datenschutzrechtlichen Vorgaben erforderlichen Dokumentation.",
      ],
    },
  ],
};

export default data;
