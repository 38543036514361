import styled from "styled-components";
import Item from "./Item/Item";
import { Area } from "../../hooks/useAreas/areas/types";
import Slider from "../../UI/Slider/Slider";

const ItemWrapper = styled.div<{ slider?: boolean }>`
  ${({ slider }) =>
    !!slider ? "padding: 0 1rem;" : "width: calc(100% / 4 - 50px * 3 / 4);"}

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ slider }) =>
      !!slider ? "padding: 0 1rem;" : "width: calc(100% / 2 - 15px / 2);"}
  }
`;

const Overview: React.FC<{ slider?: boolean }> = ({ slider = false }) => {
  const getItems = () => {
    return Object.keys(Area).map((area, i) => (
      <ItemWrapper key={i} slider={slider}>
        <Item area={area} />
      </ItemWrapper>
    ));
  };

  if (slider) {
    return <Slider items={getItems()} />;
  }

  return (
    <div className="flex flex-wrap gap-[15px] md:gap-[50px] my-16">
      {getItems()}
    </div>
  );
};

export default Overview;
