import { Area, AreaContent } from "../../useAreas/areas/types";

const data: AreaContent = {
  id: Area.GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT,
  content: [
    "Gewerblicher Rechtsschutz",
    "Der Begriff des Gewerblichen Rechtsschutzes umfasst den Schutz gewerblicher Schutzrechte (wie Patente, Marken und Designs) sowie das Lauterkeitsrecht. Das Urheberrecht schützt das geistige Eigentum.",
    "Markenrecht",
    "Auch das beste Produkt braucht einen Namen. Durch die Marke unterscheidet es sich von den Produkten anderer Unternehmen. Die Marke zeigt dem Verbraucher, von wem das Produkt stammt, und dass er sich auf eine gleich bleibende Qualität verlassen kann. Die gute Marke braucht eine gute Markenstrategie, von der Auswahl über die Anmeldung bis zur Pflege und Verteidigung.",
    "Wir unterstützen Sie",
    [
      "rechtliche Beratung bei der Entwicklung von Markenstrategien",
      "Beratung und Unterstützung bei der Anmeldung nationaler und internationaler Marken",
      "Überwachung Ihrer Marken (Markenüberwachung)",
      "Beratung bei Abmahnungen",
      "Verteidigung Ihrer Rechte bei Markenverletzung durch Dritte (gerichtlich und außergerichtlich)",
      "Beratung beim Abschluss von Lizenzvereinbarungen",
    ],

    "Lauterkeitsrecht",
    "Das Lauterkeitsrecht ist im Gesetz gegen den unlauteren Wettbewerb (UWG) sowie in zahlreichen Nebengesetzen wie dem Heilmittelwerbegesetz (HWG) geregelt.",
    "Urheberrecht",
    "Das Urheberrecht schützt die Schöpfung einer „Idee“; die gewerblichen Schutzrechte schützen die „Idee“ beispielsweise vor Verfälschungen. Gleichzeitig bieten sie dem Schöpfer die Möglichkeit den wirtschaftlichen Nutzen aus der „Idee“ zu ziehen.",
    "Was wir tun:",
    "Wir beraten und betreuen Sie bei der Durchsetzung und Verteidigung in Wettbewerbs-, Marken- und Urheberrechtsstreitigkeiten, insbesondere bei Abmahnungen, Unterlassungserklärungen, einstweiligen Verfügungen und in Klageverfahren. Darüber hinaus beraten wir Sie bei der Erlangung und Bewahrung des Schutzes Ihrer „Idee“, z.B. durch Markenanmeldungen und Markenverwaltungen, sowie die Gestaltung von Verträgen (Verlagsverträgen, Lizenzverträge, Vertriebsverträgen, Künstlerverträgen).",
    "Start-Up-Beratung IP-Recht",
    "Wir bieten Start-Ups Beratung zur Fragen des Marken-, Urheber-, Medien- und Lizenzrechts. Fragen Sie uns !",
  ],
  bullets: [
    "Abgrenzungsvereinbarung",
    "Abmahnung, Unionsmarke",
    "Domain und Marke",
    "Gebrauchsmuster- und",
    "Geschmacksmusterrecht",
    "Marke, Kennzeichen",
    "Markenanmeldung",
    "Markenlizenzvertrag",
    "Markenpiraterie",
    "Markenrecherche",
    "Markenrecht",
    "Markenstrategie",
    "Markenüberwachung",
    "Namensschutz, Domain",
    "Presserecht",
    "Titelschutz",
    "Urheberrecht",
    "Verlagsrecht",
    "Verlängerung der Marke",
    "Verwechslungsgefahr",
    "Werbung",
    "Wettbewerbsrecht",
    "Widerspruchsverfahren",
  ],
  more: [],
};

export default data;
