export enum Area {
  VERKEHRSRECHT = "VERKEHRSRECHT",
  REISERECHT = "REISERECHT",
  MEDIZINRECHT = "MEDIZINRECHT",
  ARBEITSRECHT = "ARBEITSRECHT",
  FAMILIENRECHT = "FAMILIENRECHT",
  ITUNDDATENRECHT = "ITUNDDATENRECHT",
  MIETUNDWOHNUNGSEIGENTUMSRECHT = "MIETUNDWOHNUNGSEIGENTUMSRECHT",
  GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT = "GEWERBLICHERRECHTSSCHUTZUNDURHEBERRECHT",
  ERBRECHT = "ERBRECHT",
  TRANSPORTRECHT = "TRANSPORTRECHT",
  HANDELUNDGESELLSCHAFTSRECHT = "HANDELUNDGESELLSCHAFTSRECHT",
  ALLGEMEINESZIVILRECHT = "ALLGEMEINESZIVILRECHT",
}

export type AreaContent = {
  id: Area;
  content: Array<string | string[]>;
  bullets: string[];
  more: {
    title: string;
    href: string;
  }[];
};
