import { useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import ContactForm from "./Form";
import props from "../../../redux/props";

const ContactSection = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <div className="py-28 bg-lightGray">
      <Container>
        <div className="text-xl text-center font-bold text-blue">
          {t.contact.section.headline}
        </div>
        <div className="md:w-2/3 mx-auto">
          <ContactForm />
        </div>
      </Container>
    </div>
  );
};

export default ContactSection;
